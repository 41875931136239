import React, { useEffect, useState } from 'react';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Button from '../../design-system/Button/Button';
import ChooseMuscle from '../../components/ChooseMuscle/ChooseMuscle';
import ArticleCarousel from '../../components/ArticleCarousel/ArticleCarousel';
import { useAppState } from '../../contexts/StateContext';
import { api } from '../../config/api';
import { useAuth } from '../../contexts/AuthContext';
import Spinner from '../../design-system/Spinner/Spinner';

import styles from './PreventionCategory.module.scss';

const PreventionCategory = (props) => {
	const { auth } = useAuth();

	const [category, setCategory] = useState(null);
	const [articles, setArticles] = useState([]);
	const [areArticlesLoading, setAreArticlesLoading] = useState(true);

	const appState = useAppState();

	useEffect(() => {
		api.get(`/globalPrevCategories/${props.match.params.category}`)
		.then(response => {
			setCategory(response.data);
		})
		.catch(e => {
			// TODO Page introuvable
		})

		api.get(`/globalPrevCategories/${props.match.params.category}/articles`)
		.then(response => {
			setArticles(response.data);
			setAreArticlesLoading(false);
		})
		.catch(e => {
			setAreArticlesLoading(false);
		})
	}, []);

	return category && (
		<>
			<header className={styles.categoryHeader}>
				<div className={styles.container}>
					<Breadcrumb
						type="light"
						path={[
							{ name: 'GlobalPrev', link: '/globalprev' },
							{ name: category.title },
						]}
					/>

					<div className={styles.headerText}>
						<img
							src={category.icon}
							width="32"
							height="32"
							alt={`Icône ${category.title}`}
						/>
						<p className={styles.title}>{category.title}</p>
						<p className={styles.subtitle}>{category.numberOfArticles} conseils</p>
					</div>
				</div>
			</header>

			<div className={styles.backgroundBanner}></div>
			<div className={styles.container}>
				<>
					{areArticlesLoading ? <Spinner center size="large" style={{ marginTop: '10em' }}/>
						: articles.length ?
							<ArticleCarousel articles={articles} fadeBorder/>
							:
							<h3 className={styles.noArticles}>Aucun article pour "{category.title}"</h3>
					}

					{auth.isSubscribed() ? null :
						<div className={styles.subscribeButtonContainer}>
							<Button variant="main-sm"
								onClick={() => appState.modals.subscribe.open()}
							>
								S'abonner
							</Button>
						</div>
					}
				</>
			</div>
		</>
	);
}

export default PreventionCategory;