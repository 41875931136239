import React, { useState } from 'react';

import Input from '../../../design-system/Input/Input';
import Modal from '../../../design-system/Modal/Modal';
import Button from '../../../design-system/Button/Button';
import { api } from '../../../config/api';

const ForgotPasswordModal = (props) => {
	const [email, setEmail] = useState('');

	const [success, setSuccess] = useState(null);
	const [error, setError] = useState(null);

	const resetPassword = () => {
		api.post('/password/ask_reset', { email })
			.then(response => {
				setSuccess(response.data.message);
				setError(null);
			})
			.catch(error => {
				setError(error.response.data.message[0]);
				setSuccess(null);
			});
	}

	const onClose = () => {
		setError(null);
		setSuccess(null);
		setEmail('');
		props.onClose();
	}

	return (
		<>
			<Modal
				onClose={onClose}
				show={props.show}
				size="small"
				title="Mot de passe oublié"
				success={success}
				error={error}>

				{!success ?
					<>
						<Input
							type="email"
							label="Email"
							placeholder="exemple@exemple.com"
							value={email}
							onChange={e => setEmail(e.target.value)}
						/>

						<Button variant="main" onClick={resetPassword}>Réinitialiser le mot de passe</Button>
					</>
					:
					<Button variant="main" onClick={onClose}>Fermer</Button>
				}
			</Modal>
		</>
	);
}

export default ForgotPasswordModal;