import React from 'react';
import Alert from '../Alert/Alert';

import Backdrop from '../Backdrop/Backdrop';

import logo from '../../assets/images/logo.svg'
import styles from './Modal.module.scss';

const Modal = (props) => {
	const modalAnimation = {
		transform: props.show ? 'translateY(0)' : 'translateY(-150vh)',
		opacity: props.show ? 1 : 0
	};

	return (
		<>
			<Backdrop show={props.show} />
			<div className={styles.modalContainer} style={modalAnimation} onClick={props.onClose}>
				<div onClick={event => event.stopPropagation()} className={`${styles.modal} ${props.size ? styles[props.size] : ''} ${props.additionalClass ? props.additionalClass : null}`}>
					{props.displayIcon !== false && <img alt="Logo" src={logo} className={styles.logo}></img>}
					{props.title &&
						<h1 className={styles.title}>
							{props.title}
						</h1>
					}
					{props.error && <Alert type="error" message={props.error} />}
					{props.success && <Alert type="success" message={props.success} />}
					{props.children}
				</div>
			</div>
		</>
	);
}

export default Modal;