import { useEffect, useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

import Button from '../../../../design-system/Button/Button';
import Utils from '../../../../utils';
import { useRedirect } from '../../../../hooks/useRedirect';
import { useAuth } from '../../../../contexts/AuthContext';
import { api } from '../../../../config/api';
import { useAppState } from '../../../../contexts/StateContext';
import PaymentCard from '../../../PaymentCard/PaymentCard';
import Spinner from '../../../../design-system/Spinner/Spinner';

import creditCardsImage from '../../../../assets/images/credit_cards.png';
import styles from './CheckoutForm.module.scss';
import RadioButton from '../../../../design-system/RadioButton/RadioButton';

// Custom styling can be passed to options when creating an Element.
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
		color: 'white',
		iconColor: 'white',
		fontFamily: '"Trebuchet MS", sans-serif',
		fontSize: '14px',
		fontWeight: '400',
		'::placeholder': {
			color: '#ffffffbe'
		}
    },
    invalid: {
		color: '#ff4444',
		iconColor: '#ff4444'
    },
  }
};

const CheckoutForm = (props) => {
	const { auth } = useAuth();
	const redirect = useRedirect();
	const stripe = useStripe();
	const elements = useElements();
	const appState = useAppState();

	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [defaultCard, setDefaultCard] = useState(null);
	const [useCard, setUseCard] = useState(null);

	const handleChange = (event) => {
		if (event.error) {
			setError(event.error.message);
		} else {
			setError(null);
		}
	}

	const handleSubmit = (event) => {
		event.preventDefault();
		if (!loading) {
			setLoading(true);

			if (useCard === null) {
				createPaymentMethod(elements.getElement('card'));
			}
			else {
				const customerId  = auth.getUser().customer_id;
				const priceId     = props.formula.price.id;

				createSubscription({
					customerId: customerId,
					paymentMethodId: useCard,
					priceId: priceId,
				});
			}
		}
	};

	const createPaymentMethod = (card) => {
		const billingName = `${auth.getUser().firstname} ${auth.getUser().lastname}`;
		const email       = auth.getUser().email;
		const customerId  = auth.getUser().customer_id;
		const priceId     = props.formula.price.id;
	  
		stripe.createPaymentMethod({
			type: 'card',
			card: card,
			billing_details: {
				  name: billingName,
				  email: email
			},
		}).then((result) => {
			if (result.error) {
				setError(result.error.message);
				setLoading(false);
			}
			else {
				createSubscription({
					customerId: customerId,
					paymentMethodId: result.paymentMethod.id,
					priceId: priceId,
				});
			}
		});
	  }
	
	const createSubscription = ({ customerId, paymentMethodId, priceId }) => (
		api.post('subscriptions/create-subscription', {
			customerId: customerId,
			paymentMethodId: paymentMethodId,
			priceId: priceId,
		}).then((response) => {
			const results = response.data;
			if (results.error) {
				setError(results.error.message);
				setLoading(false);
			}
			else {
				api.post('/users/token', {
					refresh_token: auth.getUser().refresh_token
				}).then((response) => {
					auth.login(response.data);
					appState.modals.subscribe.close();
					appState.modals.congratulations.open();
				}).catch((error) => {
					setError(error.message);
					setLoading(false);
				})
			}
		})
		.catch((error) => {
			setError(error.message);
			setLoading(false);
		})
	);

	useEffect(() => {
		api.get('/users/payment-cards/default')
        .then(response => {
			if (response.data) {
				setDefaultCard(response.data);
				setUseCard(response.data.payment_method_id);
			}
        }).catch(error => {})
	}, []);
	return (
		<form id="payment-form" className={styles.stripeForm} onSubmit={handleSubmit}>
			<div className="form-row">
				{defaultCard &&
					<>
						<div className={styles.paymentMethodSelect} onClick={() => setUseCard(defaultCard.payment_method_id)}>
							<RadioButton active={useCard ? true : false} variant="classic"/>
							<span>Carte par défaut</span>
						</div>
						<PaymentCard card={defaultCard}/>
					</>
				}
				<div className={styles.paymentMethodSelect} onClick={() => setUseCard(null)}>
					<RadioButton active={useCard ? false : true} variant="classic"/>
					<span>Nouvelle carte</span>
				</div>
				<CardElement
					id="card-element"
					options={CARD_ELEMENT_OPTIONS}
					onChange={handleChange}
				/>
				<div className={`card-element-errors ${styles.errors}`} role="alert">{error}</div>
			</div>
			<small className={styles.subscriptionInfos}>
				L’abonnement sera automatiquement renouvellé tous les {new Date().getDate()} du mois.
				Pour le résilier il vous suffit de nous envoyer un message à resiliation@karesport.com
			</small>

			<div className={styles.subscribeButtonWrapper}>
			{loading ?
				<Spinner size="medium"/>
				:
				<Button variant="main" type="submit" disabled={loading}>
					S'abonner pour {props.formula && `${Utils.formatPrice(props.formula.price.amount)} ${props.formula.price.currency} par ${props.formula.price.period}`}
				</Button>
			}
			</div>

			<button className={styles.confidentialityPolicy} type="button" onClick={() => redirect.redirectTo('/cgv')}>
				En vous abonnant, vous acceptez avoir lu notre <strong>politique de confifentialité</strong> et nos <strong>CGV</strong>
			</button>

			<img src={creditCardsImage} className={styles.creditCardsImage}></img>
		</form>
	);
}

export default CheckoutForm;