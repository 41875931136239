import React, { useState, useEffect } from 'react';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import ChooseMuscle from '../../components/ChooseMuscle/ChooseMuscle';
import { api } from '../../config/api';

import ExerciseIcon from '../../assets/icons/exercise_light.svg';
import styles from './PreventionExercises.module.scss';
const PreventionExercises = () => {
    const [numberOfExercises, setNumberOfExercises] = useState(0);

	useEffect(() => {
        api.get('/global_prev_exercises/count/exercises')
            .then(response => {
                setNumberOfExercises(response.data);
            });
    }, []);
    
    return (
        <>
        <header className={styles.categoryHeader}>
            <div className={styles.container}>
                <Breadcrumb
                    type="light"
                    path={[
                        { name: 'GlobalPrev', link: '/globalprev' },
                        { name: 'Exercices' },
                    ]}
                />

                <div className={styles.headerText}>
                    <img
                        src={ExerciseIcon}
                        width="32"
                        height="32"
                        alt={'Icône Exercices'}
                    />
                    <p className={styles.title}>Exercices</p>
                    <p className={styles.subtitle}>{numberOfExercises} exercices</p>
                </div>
            </div>
        </header>

        <div className={styles.backgroundBanner}></div>
        <div className={styles.container}>
            <div className={styles.chooseMuscleWrapper}>
                <ChooseMuscle width="792" />
            </div>
        </div>
    </>
    );
}

export default PreventionExercises;