import React from 'react';

import ExerciseCard from '../ExerciseCard/ExerciseCard';

import styles from './ExerciseList.module.scss';

const gradeRegex = /(GRADE|Grade|grade) +[^ ]+/

const ArticleList = (props) => {
	const exercises = props.exercises;
	const grades = {};

	if (props.splitByGrade) {
		exercises.forEach(exercise => {
			const matchGrade = exercise.title.match(gradeRegex);
			const grade = matchGrade ? matchGrade[0] : null;
			if (grade !== null) {
				if (grades[grade] !== undefined) {
					grades[grade].push(exercise);
				} else {
					grades[grade] = [exercise];
				}
			}
			else {
				if (grades.Autres !== undefined) {
					grades.Autres.push(exercise);
				} else {
					grades.Autres = [exercise];
				}
			}
		});

		// Put other grades at the end
		const otherGrades = grades.Autres;
		if (otherGrades) {
			delete grades.Autres;
			grades.Autres = otherGrades;
		}
	}

	if (props.splitByGrade) {
		return (
			<>
				{Object.keys(grades).map(grade => (
					<div className={styles.gradeContainer}>
						<h2 className={styles.gradeTitle}>{grade.toUpperCase()}</h2>
						<div className={styles.exercisesContainer}>
							{grades[grade].map(exercise => {
								return (
									<ExerciseCard
										key={exercise.id}
										exercise={exercise}
										type={props.type}
									/>
								)
							})}
						</div>
					</div>
				))}
			</>
		)
	}

	return (
		<>
			{props.exercises.map((exercise, i) => {
				return (
					<ExerciseCard
						key={`exercise${i}`}
						exercise={exercise}
						type={props.type}
					/>
				)
			})}
		</>
	)
}

export default ArticleList;