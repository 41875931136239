import React from 'react';

import ExerciseCard from '../ExerciseCard/ExerciseCard';
import Carousel from '../../design-system/Carousel/Carousel';

const ExerciseCarousel = (props) => {
    return (
        <Carousel
            activeIndex={props.activeIndex}
            fadeBorder={props.fadeBorder}
            items={props.exercises.map(exercise => (
                <ExerciseCard
                    exercise={exercise}
                    type={props.type}
                />
            ))}
            responsive={{
                sm: 1,
                md: 2,
                lg: 3,
                xl: 4,
                xxl: 5
            }}
        />
    );
}

export default ExerciseCarousel;