import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faUserAstronaut } from '@fortawesome/free-solid-svg-icons';

import Input from "../../design-system/Input/Input";
import SportCard from '../../components/SportCard/SportCard';
import SportCarousel from '../../components/SportCarousel/SportCarousel';
import Button from '../../design-system/Button/Button';
import { api } from "../../config/api";
import { useAppState } from "../../contexts/StateContext";
import { useAuth } from "../../contexts/AuthContext";
import Spinner from '../../design-system/Spinner/Spinner';

import styles from "./Sports.module.scss";

const Sports = (props) => {
	const { auth } = useAuth();

	const appState = useAppState();

	const [research, setResearch] = useState('');
	const [sports, setSports] = useState([]);
	const [areSportsLoading, setAreSportsLoading] = useState(true);

	const searchSport = research => {
		research = research.toLowerCase();
		// No empty search
		if (research.trim().length !== 0) {
			api.get(`/sports/search/${research.trim()}`)
			.then(response => {
				setSports(response.data);
			})
		}
	}

	const getAllSports = () => {
		setAreSportsLoading(true);
		api.get('/sports')
			.then(response => {
				setSports(response.data);
				setAreSportsLoading(false);
			})
			.catch(() => {
				setAreSportsLoading(false);
			})
	}

	useEffect(() => {
		getAllSports();
	}, [])

	useEffect(() => {
		if (research.trim().length !== 0) {
			searchSport(research.trim());
		}
		else {
			getAllSports();
		}
	}, [research]);

	return (
		<>
			<header className={styles.header}>
				<div className={styles.container}>
					<h1 className={styles.title}>Sélectionnez un sport</h1>
					<p className={styles.subtitle}>
						Parcourez les exercices de prévention des blessures et les bonnes pratiques de votre sport
					</p>

					{/* <div className={styles.searchContainer}>
						<Input
							type="text"
							icon={faSearch}
							placeholder="Rechercher un sport"
							value={research}
							onChange={e => setResearch(e.target.value)}
						/>
					</div> */}
				</div>
			</header>

			<div className={styles.backgroundBanner}></div>
			<div className={styles.container}>
				<section className={styles.sportsContainer}>
					{sports.length ?
						// <SportCarousel sports={sports.concat(sports)}/>
						<div style={{ display: 'flex', flexWrap: 'wrap' }}>
							{ sports.map(sport => (
								<SportCard sport={sport} key={sport.id} />
							)) }
						</div>
						:
						areSportsLoading ?
							<Spinner center size="large" style={{ marginTop: '10em' }}/>
							:
							<div className={styles.noResults}>
								<FontAwesomeIcon icon={faUserAstronaut} size="2x" />
								<h6>Aucun sport de correspond à votre recherche</h6>
							</div>
					}
				</section>

				{(!auth.isSubscribed() && sports.length !== 0) &&
					<div className={styles.subscribeButtonWrapper}>
						<Button variant="main-sm" onClick={appState.modals.subscribe.open}>S'abonner</Button>
					</div>
				}
			</div>
		</>
	);
}

export default Sports;