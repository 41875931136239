export const APP = {
	name: 'Karesport',
	stripe: {
		publicKey: 'pk_test_51IDRO3Ar4sHxMzdPBDYDrkSiIFBsjy5Os5fLHfu14W9dxerbrGbj8OQbZv0pLBwmYZCLxuFyJc6rzKvzDA4Yk0T900gwETHNrE',
	}
}

/**
 * Screen breakpoints in px
 */
export const BREAKPOINTS = {
	xs: 0,
	sm: 576,
	md: 768,
	lg: 992,
	xl: 1200,
	xxl: 1500
}