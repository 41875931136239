import React, { useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';

import ExerciseList from '../../components/ExerciseList/ExerciseList';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import OtherSportStages from '../../components/OtherSportStages/OtherSportsStages';
import { api } from '../../config/api';
import Spinner from '../../design-system/Spinner/Spinner';

import styles from './SportStage.module.scss';

const SportStage = (props) => {
	const stageId = parseInt(props.match.params.stage);

	const [stage, setStage] = useState(null);
	const [exercises, setExercises] = useState([]);
	const [exercisesLoading, setExercisesLoading] = useState(true);

	useEffect(() => {
		setStage(null);
		setExercises([]);

		api.get(`/stages/${stageId}`)
        .then(response => {
            setStage(response.data);
		})
		
        api.get(`/stages/${stageId}/exercises`)
        .then(response => {
            setExercises(response.data);
			setExercisesLoading(false);
        })
	}, [stageId])

	return stage && (
		<>
			<div className={styles.stageHeader}>
				<div className={styles.container}>
					<Breadcrumb
						type="light"
						path={[
							{ name: 'Sports', link: '/sports' },
							{ name: stage.sport.title, link: `/sports/${stage.sport.id}` },
							{ name: stage.title },
						]}
					/>

					<div className={styles.headerText}>
						<p className={styles.title}>{stage.title}</p>
						{ stage.presentation &&
							<section className={styles.presentation}>
								{ ReactHtmlParser(stage.presentation) }
							</section>
						}
						<p className={styles.subtitle}>{exercises.length} séances disponibles</p>
					</div>
				</div>
			</div>

			<div className={styles.backgroundBanner}></div>
			<div className={styles.container}>
				<div>
					{exercisesLoading ?
						<Spinner center size="large" style={{ marginTop: '10em' }}/>
					:
						<ExerciseList exercises={exercises} type="sports" splitByGrade />
					}
				</div>

				<OtherSportStages sport={stage.sport} exclude={stage.id} />
			</div>
		</>
	);
}

export default SportStage;