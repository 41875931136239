import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';

import Teammate from './Teammate/Teammate';
import Button from '../../design-system/Button/Button';
import { api } from '../../config/api';
import { useAppState } from '../../contexts/StateContext';
import { useAuth } from '../../contexts/AuthContext';
import Spinner from '../../design-system/Spinner/Spinner';

import logo from '../../assets/images/logo.svg';
import styles from './Team.module.scss';

const Team = () => {
    const [team, setTeam] = useState([]);
    const [teamPresentation, setTeamPresentation] = useState(null)
    const [isTeamLoading, setIsTeamLoading] = useState(true);

    const appState = useAppState();
	const { auth } = useAuth();

	const onSubscribeClicked = () => {
		if (auth.isLogged()) {
			appState.modals.subscribe.open();
		}
		else {
			appState.modals.login.open();
		}
	}

    useEffect(() => {
        api.get('/globalSettings/teamPresentation').then(response => {
            setTeamPresentation(response.data);
        }).catch(() => {})

        api.get('/team').then(response => {
            setTeam(response.data);
            setIsTeamLoading(false);
        }).catch(() => {
            setIsTeamLoading(false);
        })
    }, []);

    return (
        <>
            <header className={styles.header}>
                <div className={styles.container}>
                    <h1 className={styles.title}>Une équipe de professionnels de santé et du sport</h1>
                    { teamPresentation &&
                        <h6 className={styles.subtitle}>{ ReactHtmlParser(teamPresentation) }</h6>
                    }
                </div>
            </header>

            <div className={styles.container}>
                <div className={styles.teamList}>
                    {isTeamLoading ? <Spinner center size="large"/> : team.map((teammate, i) => (
                        <div className={styles.teammateWrapper} key={`teammate${i}`}>
                            <Teammate teammate={teammate}/>
                        </div>
                    ))}
                </div>

                {!auth.isSubscribed() &&
                <>
                    <img src={logo} alt="Logo Karesport blanc" className={styles.logoWhite}/>
                    <h2 className={styles.callToActionTitle}>Convaincu de nos expertises ?</h2>
                    <div className={styles.callToActionWrapper}>
                        <Button variant="main" onClick={onSubscribeClicked}>S'abonner</Button>
                    </div>
                </>
                }
            </div>
        </>
    );
}

export default Team;