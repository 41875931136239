import React, { useState, useEffect } from 'react';

import { useRedirect } from '../../hooks/useRedirect';
import ExerciseIcon from '../../assets/icons/exercise_light.svg';
import ExerciseImage from '../../assets/images/categories_background/exercise.jpg';
import Spinner from '../../design-system/Spinner/Spinner';

import styles from './PreventionCategoryCard.module.scss';
import { api } from '../../config/api';

const PreventionCategoryCard = (props) => {
	const redirect = useRedirect();

	const isExercise = props.category === 'exercises';
	
	const [numberOfExercises, setNumberOfExercises] = useState(0);
	const [isNumberLoading, setIsNumberLoading] = useState(isExercise ? true : false);

	const onClick = () => {
		if (!isExercise) {
			redirect.redirectTo(`/globalprev/${props.category.id}`);
		}
		else {
			redirect.redirectTo('/globalprev/exercises/categories');
		}
	}

	useEffect(() => {
		if (isExercise) {
			api.get('/global_prev_exercises/count/exercises')
				.then(response => {
					setNumberOfExercises(response.data);
					setIsNumberLoading(false);
				})
				.catch(() => {
					setIsNumberLoading(false);
				});
		}
	}, []);

	return (
		<div className={`${styles.adviceCategory} ${props.size === 'small' ? styles.small : ''}`} onClick={onClick}>
			<div className={styles.background} style={{ backgroundImage: `url(${isExercise ? ExerciseImage : props.category.image})` }}></div>
			<div className={styles.contentContainer}>
				<div className={styles.content}>
					<img
						src={isExercise ? ExerciseIcon : props.category.icon}
						width="32"
						height="32"
						alt={`Icône ${isExercise ? 'Exercises' : props.category.title}`}
						className={styles.icon}
					/>
					<div className={styles.text}>
						<p className={styles.categoryTitle}>{isExercise ? 'Exercises' : props.category.title}</p>
						<p className={styles.numberOfAdvices}>
							{isNumberLoading ?
								<Spinner size="small"/>
								:
								isExercise ?
									`${numberOfExercises} exercices`
									:
									`${props.category.numberOfArticles} conseils`
							}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PreventionCategoryCard;