import React from 'react';

import Modal from '../../../design-system/Modal/Modal';
import { APP } from '../../../config/config';
import { useAuth } from '../../../contexts/AuthContext';
import { useRedirect } from '../../../hooks/useRedirect';

import styles from './WelcomeModal.module.scss';
import Button from '../../../design-system/Button/Button';

const WelcomeModal = (props) => {
	const { auth } = useAuth();
	const redirect = useRedirect();

	const onButtonClick = () => {
		props.onClose();
		redirect.redirectTo('/globalprev');
	}

	return (
		<Modal
			onClose={props.onClose}
			show={props.show}
			size="small">
			<h1 className={styles.title}>Bienvenue {auth.isLogged() ? auth.getUser().firstname : null} !</h1>
			<p className={styles.message}>Merci d'avoir rejoint {APP.name}. Profitez désormais de tous nos conseils et vidéos gratuites !</p>

			<Button variant="main" onClick={onButtonClick}>Commencer</Button>
		</Modal>
	);
}

export default WelcomeModal;