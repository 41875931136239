import React from 'react';

import { useRedirect } from '../../hooks/useRedirect';
import Utils from '../../utils';

import styles from './StageCard.module.scss';

const StageCard = (props) => {
	const redirect = useRedirect();

	const onClick = () => {
		redirect.redirectTo(
			`/sports/${props.sport.id}/${props.stage.id}`
		);
	}

	return (
		<div className={styles.stage} onClick={onClick}>
			<div className={styles.background} style={{ backgroundImage: `url(${props.stage.image})` }}></div>

			<div className={styles.contentContainer}>
				<div className={styles.content}>
					<p className={styles.stageTitle}>{props.stage.title}</p>
				</div>
			</div>
		</div>
	);
};

export default StageCard;