import React, { useState, useEffect } from 'react';

import Icon from '../../design-system/Icon/Icon';
import { useAuth } from '../../contexts/AuthContext';
import { useAppState } from '../../contexts/StateContext';

import styles from './ExerciseCard.module.scss';

const ExerciseCard = (props) => {
	const appState = useAppState();
	const { auth } = useAuth();

	const [canOpenExercise, setCanOpenExercise] = useState(
		props.type === 'sports'
			? auth.canOpenSportExercise(props.exercise)
			: auth.canOpenGlobalPrevExercise(props.exercise)
	)

	useEffect(() => {
		setCanOpenExercise(
			props.type === 'sports'
				? auth.canOpenSportExercise(props.exercise)
				: auth.canOpenGlobalPrevExercise(props.exercise)
		)
	}, [props.exercise])

	const onClickAction = () => {
		if (canOpenExercise) {
			appState.modals.sportExercise.setSelectedExercise(props.exercise.id, props.type);
			if (appState.modals.sportExercise.isOpen) {
				appState.modals.sportExercise.close();
				setTimeout(() => {
					appState.modals.sportExercise.open();
				}, 200)
			}
			else {
				appState.modals.sportExercise.open();
			}
		}
		else if (!auth.isLogged()) {
			appState.modals.sportExercise.close();
			appState.modals.login.open();
		}
		else {
			appState.modals.sportExercise.close();
			appState.modals.subscribe.open();
		}
	}

	return (
		<>
			<div className={styles.exerciseCard}>
				<div className={styles.image} onClick={onClickAction} style={{ backgroundImage: `url("${props.exercise.thumbnail}")` }}>
					{canOpenExercise ?
						<div className={styles.playIconWrapper}>
							<div className={styles.playIcon}>
								<Icon icon="play" size="84px" />
							</div>
						</div> : null
					}
					{!canOpenExercise && props.exercise.privacy === 'authenticated' ?
						<div className={styles.locked}>
							<Icon icon="lock" type="light" />
							<div className={styles.lockMessage}>Pour débloquer la vidéo, inscrivez-vous</div>
						</div> : null
					}
					{!canOpenExercise && props.exercise.privacy === 'subscribed' ?
						<div className={styles.locked}>
							<Icon icon={'credit-card'} type="light" />
							<div className={styles.lockMessage}>Pour débloquer un accès complet, abonnez-vous</div>
						</div> : null
					}
				</div>

				<p className={styles.title}>{props.exercise.title}</p>
				<div className={styles.durationWrapper}>
					<Icon icon="stopwatch" size="20px" />
					<p className={styles.duration}>{props.exercise.video.duration_in_minutes}mn</p>
				</div>
			</div>
		</>
	);
}

export default ExerciseCard;