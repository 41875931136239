import React, { useState } from 'react';
import Alert from '../../design-system/Alert/Alert';

import Input from '../../design-system/Input/Input';
import Button from '../../design-system/Button/Button';
import { useAppState } from '../../contexts/StateContext';
import { api } from '../../config/api';
import { useRedirect } from '../../hooks/useRedirect';

import styles from './ResetPassword.module.scss';

const ResetPassword = (props) => {
	const appState = useAppState();
	const redirect = useRedirect();

	const [success, setSuccess] = useState('');
	const [error, setError] = useState('');
	const [password, setPassword] = useState('');
	const [passwordConfirm, setPasswordConfirm] = useState('');

	const token = props.match.params.token;
	const isChange = token === 'account';

	const onSubmit = () => {
		const url = isChange ? '/users/changePassword' : '/password/reset';
		api.post(url, { token, password, passwordConfirm })
			.then(response => {
				if (isChange) {
					redirect.redirectTo('/account');
				}
				else {
					setSuccess(response.data.message);
					setError(null);
				}
			})
			.catch(error => {
				const message = error.response.data.statusCode === 403 ? 'Vous devez être connecté' : error.response.data.message;
				setError(typeof message === 'object' ? message[0] : message);
				setSuccess(null);
			});
	}

	return (
		<>
			<div className={styles.resetPassword}>
				<div className={styles.content}>
					{ isChange ?
						<h1>Modifier le mot de passe</h1>
						:
						<h1>Réinitialiser le mot de passe</h1>
					}

					{error ?
						<Alert type="error" message={error} />
						: null}

					{success ?
						<>
							<Alert type="success" message={success} />

							<div className={styles.buttonWrapper}>
								<Button variant="main" onClick={() => appState.modals.login.open()}>Se connecter</Button>
							</div>
						</>
						:
						<>
							<Input
								type="password"
								label="Nouveau mot de passe"
								placeholder="••••••••"
								value={password}
								onChange={e => setPassword(e.target.value)}
								required
								message="Minimum 8 caractères"
							/>
							<Input
								type="password"
								label="Confirmation du mot de passe"
								placeholder="••••••••"
								value={passwordConfirm}
								onChange={e => setPasswordConfirm(e.target.value)}
								required
							/>

							<div className={styles.buttonWrapper}>
								<Button variant="main" onClick={onSubmit}>Confirmer</Button>
							</div>
						</>
					}
				</div>
			</div>
		</>
	)
}

export default ResetPassword;