import React, { useEffect, useState, useRef } from 'react';

import Button from '../../design-system/Button/Button';
import PreventionCategoryCard from '../../components/PreventionCategoryCard/PreventionCategoryCard';
import SportCard from '../../components/SportCard/SportCard';
import { api } from '../../config/api';
import { useAuth } from '../../contexts/AuthContext';
import SubscriptionFormula from './SubscriptionFormula/SubscriptionFormula';
import { useAppState } from '../../contexts/StateContext';
import Case from './Case/Case';

import iphone from '../../assets/images/home_iphone.png';
import homeGallery from '../../assets/images/home_gallery.png';
import logo from '../../assets/images/logo.svg';
import step from '../../assets/images/step.svg';
import styles from './Home.module.scss';
import Icon from '../../design-system/Icon/Icon';

const Home = (props) => {
	const appState = useAppState();
	const { auth } = useAuth();

	const [formulas, setFormulas] = useState([]);
	const [sports, setSports] = useState([]);
	const [categories, setCategories] = useState([]);
	const [video, setVideo] = useState(null);
	const [showThumbnail, setShowThumbnail] = useState(true);

	const trailerRef = useRef();

	const onSubscribeClicked = () => {
		if (auth.isLogged()) {
			appState.modals.subscribe.open();
		}
		else {
			appState.modals.login.open();
		}
	}

	const playTrailer = () => {
		if (trailerRef.current) {
			setShowThumbnail(false);
			trailerRef.current.play();
		}
	}

	useEffect(() => {
		api.get('/subscriptions')
			.then(response => {
				setFormulas(response.data);
			})

		api.get('/sports?limit=6')
			.then(response => {
				setSports(response.data);
			})

		api.get('/globalPrevCategories?limit=6')
			.then(response => {
				setCategories(response.data);
			})

		api.get('/globalSettings/landingPageVideo')
			.then(response => {
				console.log(response.data)
				setVideo(response.data)
			})
	}, []);

	return (
		<>
			<section className={styles.sectionOne}>
				<div className={styles.container}>
					<div>
						<h1>
							Prévention des blessures<br></br>
							Réhabilitation<br></br>
							Optimisation des performances
						</h1>

						{ !auth.isSubscribed() && <Button variant="main" onClick={onSubscribeClicked}>S'abonner</Button> }

						<img src={logo} alt="Logo Karesport" className={styles.logo}/>
					</div>
					<div>
						<img src={iphone} alt="Prévisualisation smartphone" className={styles.iphone}/>
					</div>
				</div>
			</section>

			<div className={styles.container}>
				<section className={styles.sectionTwo}>
					<h1 className={styles.sectionTitle}>Comment ça marche ?</h1>

					<div className={styles.steps}>
						<div>
							<div>
								<div className={styles.stepWrapper}>
									<Case number="1" title="Vous êtes un professionnel du sport">
										Sélectionnez votre sport, abonnez-vous et ayez accès aux différents programmes de prévention des blessures. Vous pourrez sélectionner les différents protocoles adaptés au moment de la saison, ou avoir accès à des séances spécifiques de prévention en fonction des zones du corps.
									</Case>
								</div>
								<div className={styles.stepWrapper}>
									<Case number="3" title="Vous êtes professionnel de santé">
										Rendez-vous dans la rubrique GlobalPrev, sélectionnez l'onglet "Professionnels de santé", abonnez-vous et ayez accès sans restrictions à toutes les vidéos et protocoles de préventions de GlobalPrev
									</Case>
								</div>
							</div>
						</div>
						<div>
							<img src={step} alt="Fil d'ariane" className={styles.stepLines}/>
						</div>
						<div className={styles.stepsRight}>
							<Case number="2" title="Vous êtes sportif occasionnel ou de haut vol">
								Rendez-vous dans la rubrique GlobalPrev, sélectionnez l'onglet "Sportifs", abonnez-vous et ayez accès à un ensemble d'exercice de la zone de votre choix (épaules, genou, cheville, lombaire, ...)
							</Case>
						</div>
					</div>

					<div className={styles.stepsSmall}>
						<div className={styles.stepWrapper}>
							<Case number="1" title="Vous êtes un professionnel du sport">
								Sélectionnez votre sport, abonnez-vous et ayez accès aux différents programmes de prévention des blessures. Vous pourrez sélectionner les différents protocoles adaptés au moment de la saison, ou avoir accès à des séances spécifiques de prévention en fonction des zones du corps.
							</Case>
						</div>
						<div className={styles.stepWrapper}>
							<Case number="2" title="Vous êtes sportif occasionnel ou de haut vol">
								Rendez-vous dans la rubrique GlobalPrev, sélectionnez l'onglet "Sportifs", abonnez-vous et ayez accès à un ensemble d'exercice de la zone de votre choix (épaules, genou, cheville, lombaire...)
							</Case>
						</div>
						<Case number="3" title="Vous êtes professionnel de santé">
							Rendez-vous dans la rubrique GlobalPrev, sélectionnez l'onglet "Professionnels de santé", abonnez-vous et ayez accès sans restrictions à toutes les vidéos et protocoles de préventions de GlobalPrev
						</Case>
					</div>

					{ !auth.isSubscribed() &&
						<div style={{ marginTop: '2em' }}>
							<Button variant="main" onClick={onSubscribeClicked}>S'abonner</Button>
						</div>
					}

					{ video &&
						<div className={styles.trailerWrapper}>
							{showThumbnail &&
								<div className={styles.trailerThumbnail}>
									<div className={styles.trailerThumbnailOverlay}>
										<button className={styles.playIconWrapper} onClick={playTrailer}>
											<Icon icon="play" size="84px" />
										</button>
									</div>
								</div>
							}
							<video ref={trailerRef} className={styles.trailerVideo} controls onEnded={() => setShowThumbnail(true)}>
								<source src={video.file} type="video/mp4" />
								Votre navigateur ne supporte pas ce type de vidéos.
							</video>
						</div>
					}
				</section>
			</div>

			<section className={styles.sectionThree}>
				<div className={styles.gradientBlackUp}></div>

				<div className={styles.container}>
					<h1 className={styles.sectionTitle}>Suivre les conseils de professionnels de santé et du sport</h1>
					<h6 className={styles.sectionSubtitle}>Besoin d’un accompagnement structuré, découvrez toutes nos formules</h6>

					<div className={styles.subscriptionFormulasContainer}>
						{formulas.map((formula, i) => {
							return (
								<SubscriptionFormula
									key={`subscriptionFormula${i}`}
									formula={formula}
									selected={i === 0 ? true : false}
									onButtonClicked={() => {
										appState.modals.subscribe.setSelectedFormula(formula.id);
										onSubscribeClicked();
									}}
								/>
							)
						})}
					</div>
				</div>

				<div className={styles.gradientBlackDown}></div>
			</section>

			<img src={homeGallery} alt="Galerie d'images de sport" className={styles.homeGallery}/>

			<div className={`${styles.container} ${styles.bottomSection}`}>
				<section className={styles.sectionFour}>
					<h1 className={styles.sectionTitle}>Des conseils spécifiques pour chaque sport</h1>

					<div className={styles.sports}>
						{sports.slice(0, 6).map((sport, i) => {
							return (
								<SportCard
									sport={sport}
									key={`sport${i}`}
								/>
							);
						})}
					</div>

					<div className={styles.sportButton}>
						<Button variant="main" link="/sports">Voir tous les sports</Button>
					</div>
				</section>

				<section className={styles.sectionFive}>
					<h1 className={styles.sectionTitle}>Accédez à GlobalPrev</h1>

					<div className={styles.preventionCategories}>
						{Object.keys(categories).slice(0, 6).map(key => {
							return (
								<PreventionCategoryCard
									key={key}
									category={categories[key]}
									size="small"
								/>
							);
						})}
					</div>

					<div className={styles.preventionButton}>
						<Button variant="main" link="/globalprev">Voir toutes nos préventions</Button>
					</div>
				</section>
			</div>
		</>
	);
}

export default Home;