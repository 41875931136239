import React, { useState, useEffect } from 'react';

import humanBodyImage from '../../assets/images/human_body.png';
import styles from './ChooseMuscle.module.scss';
import { useRedirect } from '../../hooks/useRedirect';
import { api } from '../../config/api';

const ChooseMuscle = (props) => {
	const [selectedMuscle, setSelectedMuscle] = useState(null);
	const [bodyParts, setBodyParts] = useState([])
	const [areas, setAreas] = useState([]);

	const redirect = useRedirect();

	useEffect(() => {
        api.get('/bodyParts')
            .then(response => {
				setBodyParts(response.data)
                setAreas(_extractBodyAreas(response.data));
            });
    }, []);

	const _extractBodyAreas = (bodyParts) => {
		const bodyAreas = []

		bodyParts.forEach(bodyPart => {
			bodyPart.areas.forEach(area => {
				bodyAreas.push({
					...area,
					bodyPartName : bodyPart.name,
					bodyPartId : bodyPart.id
				})
			})
		})

		return bodyAreas
	}

	return (
		<div className={styles.chooseMuscle}>
			<h6>{selectedMuscle || 'Cliquez sur une partie du corps à faire travailler'}</h6>

			<div className={`${styles.imageWrapper} ${styles.computer}`}>
				<img
					alt="Muscles du corps humain"
					src={humanBodyImage}
					width={props.width}
					height={props.height}
				/>
				<div className={styles.areas}>
					{areas.map(area => {
						const style = {
							top: `${area.posY}%`,
							left: `${area.posX}%`,
							width: `${area.width}%`,
							height: `${area.height}%`,
							transform: `rotate(${area.rotation}deg)`
						}

						return (
							<div
								key={area.id}
								className={styles.area}
								style={style}
								onMouseEnter={() => setSelectedMuscle(area.bodyPartName)}
								onMouseLeave={() => setSelectedMuscle(null)}
								onClick={() => redirect.redirectTo(`/globalprev/exercises/categories/${area.bodyPartId}`)}
								title={area.bodyPartName}
							/>
						);
					})}
				</div>
			</div>

			<div className={styles.mobile}>
				{bodyParts.map(bodyPart => (
					<a
						key={bodyPart.id}
						className={styles.areaMobileLink}
						href={`/globalprev/exercises/categories/${bodyPart.id}`}
					>
						{ bodyPart.name }
					</a>
				))}
			</div>
		</div>
	);
}

export default ChooseMuscle;