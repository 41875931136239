import React from 'react';

import styles from './Teammate.module.scss';

const Teammate = (props) => {
  return (
      <article className={styles.teammate}>
          <div className={styles.image} style={{ backgroundImage: `url(${props.teammate.image})` }}></div>
          <div className={styles.text}>
            <h1 className={styles.name}>{ props.teammate.firstname } { props.teammate.lastname }</h1>
            <p className={styles.description}>{ props.teammate.description }</p>
            <p className={styles.role}>{ props.teammate.role }</p>
          </div>
      </article>
  );
}

export default Teammate;