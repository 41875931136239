import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

import { useRedirect } from '../../hooks/useRedirect';

import styles from './Dropdown.module.scss';

const Dropdown = (props) => {
	const [show, setShow] = useState(false);

	const redirect = useRedirect();

	useEffect(() => {
		if (props.openOn === 'click') {
			document.addEventListener('click', onDocumentClicked, false);
			return () => {
				document.removeEventListener('click', onDocumentClicked, false);
			}
		}
	}, [props.openOn]);

	const isNodeDescendantOf = (child, parent) => {
		let node = child.parentNode;
		while (node != null) {
			if (node == parent) {
				return true;
			}
			node = node.parentNode;
		}
		return false;
   	}

	const onDocumentClicked = e => {
		const dropdowns = document.getElementsByClassName(styles.dropdown);

		let setToFalse = true;
		for (let dropdown of dropdowns) {
			if (isNodeDescendantOf(e.target, dropdown)) {
				setToFalse = false;
			}
		}

		if (setToFalse) setShow(false);
	}

	const onItemClicked = item => {
		if (item.url) {
			redirect.redirectTo(item.link);
		}
		if (item.onClick) {
			item.onClick();
		}

		if (props.onItemClicked) props.onItemClicked();
	}

	return (
		<div className={styles.dropdown}>
			<li
				onClick={() => props.openOn === 'click' ? setShow(!show) : null}
				onMouseEnter={() => props.openOn === 'hover' ? setShow(true) : null}
				onMouseLeave={() => props.openOn === 'hover' ? setShow(false) : null}
			>
				{props.icon ? < FontAwesomeIcon icon={props.icon} /> : null} {props.title} < FontAwesomeIcon icon={faCaretDown} />

				{show &&
					<ul>
						{props.items.map((item, i) => (
							<li className={styles.item} key={`dropdownItem${i}`} onClick={() => onItemClicked(item)}>
								{item.icon} {item.name}
							</li>
						))}
					</ul>
				}
			</li>
		</div >
	)
}

export default Dropdown;