import React from 'react'

import styles from './ServerDown.module.scss';
import logo from '../../assets/images/logo.svg';

const ServerDown = (props) => {
  return (
	  <section className={styles.serverDownSection}>
		  <div className={styles.container}>
			<img src={logo} alt="Logo de Karesport" className={styles.karesportLogo} />
			<h1 className={styles.title}>Oups...</h1>
			<h2 className={styles.message}>Nos serveurs rencontrent actuellement quelques problèmes. Merci de contacter l'admistration.</h2>
		  </div>
	  </section>
  )
}

export default ServerDown