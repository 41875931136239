import React from 'react';

import ArticleCard from '../ArticleCard/ArticleCard';
import Carousel from '../../design-system/Carousel/Carousel';

import styles from './ArticleCarousel.module.scss';

const ArticleCarousel = (props) => { 
    return (
        <Carousel
            activeIndex={props.activeIndex}
            fadeBorder={props.fadeBorder}
            items={props.articles.map(article => (
                <ArticleCard
                    article={article}
                    style={{ marginLeft: 'auto', marginRight: 'auto' }}
                />
            ))}
            responsive={{
                sm: 1,
                md: 2,
                lg: 3,
                xl: 4,
                xxl: 5
            }}
        />
    );
}

export default ArticleCarousel;


