import React, { useState } from 'react';

import { api } from '../../../config/api';

import Input from '../../../design-system/Input/Input';
import Modal from '../../../design-system/Modal/Modal';
import Button from '../../../design-system/Button/Button';
import { APP } from '../../../config/config';
import { useAuth } from '../../../contexts/AuthContext';
import WelcomeModal from '../WelcomeModal/WelcomeModal';
import { useRedirect } from '../../../hooks/useRedirect';

import styles from './RegisterModal.module.scss';

const RegisterModal = (props) => {
	const { auth } = useAuth();

	const redirect = useRedirect();

	const [openWelcome, setOpenWelcome] = useState(false);

	const [firstname, setFirstname] = useState("");
	const [lastname, setLastname] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [passwordConfirm, setPasswordConfirm] = useState("");

	const [error, setError] = useState(null);

	const register = () => {
		api.post('/auth/register', {
			firstname,
			lastname,
			email,
			password,
			passwordConfirm
		})
			.then(response => {
				api.post('/auth/login', { email, password })
					.then(response => {
						const data = response.data;
						auth.login(data.token);
						props.onClose();
						setOpenWelcome(true);
					})
					.catch(error => {
						setError(error.response.data.errors.global);
					});
			})
			.catch(error => {
				if (typeof error.response.data.message === 'object') {
					setError(error.response.data.message[0]);
				}
				else {
					setError(error.response.data.message);
				}
			})
	}

	const onClose = () => {
		props.onClose();
		setError(null);
	}

	return (
		<>
			<Modal
				onClose={onClose}
				show={props.show}
				title={`S'inscrire sur ${APP.name}`}
				error={error}>
				<Input
					type="text"
					label="Prénom"
					value={firstname}
					onChange={e => setFirstname(e.target.value)}
					required
				/>
				<Input
					type="text"
					label="Nom"
					value={lastname}
					onChange={e => setLastname(e.target.value)}
					required
				/>
				<Input
					type="email"
					label="Email"
					placeholder="exemple@exemple.com"
					value={email}
					onChange={e => setEmail(e.target.value)}
					required
				/>
				<Input
					type="password"
					label="Mot de passe"
					placeholder="••••••••"
					value={password}
					onChange={e => setPassword(e.target.value)}
					required
					message="Minimum 8 caractères"
				/>
				<Input
					type="password"
					label="Confirmation du mot de passe"
					placeholder="••••••••"
					value={passwordConfirm}
					onChange={e => setPasswordConfirm(e.target.value)}
					required
				/>

				<div className={styles.registerButtonWrapper}>
					<Button variant="main" onClick={register}>S'inscrire</Button>
				</div>

				<button className={styles.confidentialityPolicy} onClick={() => {
					props.onClose();
					redirect.redirectTo('/cgv')
				}}>
					En vous inscrivant, vous acceptez avoir lu notre <strong>politique de confifentialité</strong> et nos <strong>CGV</strong>
				</button>
			</Modal>

			<WelcomeModal show={openWelcome} onClose={() => setOpenWelcome(false)} />
		</>
	);
}

export default RegisterModal;