import React from 'react';

import styles from './PaymentCard.module.scss';

const PaymentCard = (props) => {
    
    const getFormattedExpirationDate = () => {
        let month = `${props.card.exp_month}`;
        let year  = `${props.card.exp_year}`;

        if (month.length !== 2)
            month = `0${month}`;

        year = year.slice(-2);

        return `${month} / ${year}`;
    }

    return (
        <article className={styles.paymentCard}>
            <div className={styles.title}>
                <span className={styles.label}>Type</span>
                {props.card.brand}
            </div>
            <div className={styles.number}>
                <span className={styles.label}>Numéro</span>
                **** **** **** {props.card.last4}
            </div>
            <div className={styles.expires}>
                <span className={styles.label}>Expire</span>
                { getFormattedExpirationDate() }
            </div>
        </article>
    );
}

export default PaymentCard;