import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faUserCircle, faUser } from '@fortawesome/free-solid-svg-icons';

import NavLinkScrollTop from '../../components/NavLinkScrollTop/NavLinkScrollTop';
import Button from '../../design-system/Button/Button';
import { useAuth } from '../../contexts/AuthContext';
import Dropdown from '../../design-system/Dropdown/Dropdown';
import Backdrop from '../../design-system/Backdrop/Backdrop';
import { useAppState } from '../../contexts/StateContext';
import { NAVIGATION } from '../../config/constants';
import { useRedirect } from '../../hooks/useRedirect';

import styles from './SideNav.module.scss';

const SideNav = (props) => {
	const appState = useAppState();
	const redirect = useRedirect();
	const { auth } = useAuth();

	const openLoginModal = () => {
		props.onClose();
		appState.modals.login.open();
	}

	const openSubscribeModal = () => {
		props.onClose();
		appState.modals.subscribe.open();
	}

	return (
		<>
			<Backdrop
				show={props.show}
				clicked={props.onClose}
				className={styles.sideNavBackdrop}
			/>

			<div className={`${styles.sidenav} ${props.show ? styles.open : ''}`}>
				{auth.isSubscribed() ?
					<div className={styles.premium}>
						<span>Premium</span>
					</div> : null
				}
				<ul className={styles.navigationButtons}>
					{NAVIGATION.map((navItem, i) => {
						return (
							<li key={`nav${i}`} className={`${styles.navigationButton}`} onClick={props.onClose}>
								<NavLinkScrollTop to={navItem.link} activeClassName={styles.active}>
									{navItem.name}
								</NavLinkScrollTop>
							</li>
						);
					})}
				</ul>

				{auth.isLogged() ?
					<>
						<div className={styles.userDropdownWraper}>
							<Dropdown
								title={auth.getUser().firstname}
								icon={faUserCircle}
								openOn="click"
								onItemClicked={props.onClose}
								items={[
									{ onClick: () => redirect.redirectTo('/account'), name: "Mon compte", icon: <FontAwesomeIcon icon={faUser} /> },
									{ onClick: auth.logout, name: "Déconnexion", icon: <FontAwesomeIcon icon={faSignOutAlt} /> },
								]}
							/>
						</div>
						{auth.isSubscribed() ? null :
							<Button
								variant="main-invert-sm"
								onClick={openSubscribeModal}
							>
								S'abonner
							</Button>
						}
					</>
					:
					<div className={styles.buttonsWrapper}>
						<Button variant="main-invert-outline-sm" onClick={openLoginModal}>Se connecter</Button>

						<Button
							variant="main-invert-sm"
							onClick={openSubscribeModal}
						>
							S'abonner
						</Button>
					</div>
				}
			</div>
		</>
	);
};

export default SideNav;