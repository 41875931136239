import React, { useState, useEffect } from 'react'

import ServiceCard from './ServiceCard/ServiceCard'
import Input from '../../design-system/Input/Input';
import Utils from '../../utils'
import { api } from '../../config/api'

import styles from './Administration.module.scss'
import ForestAdminLogo from '../../assets/images/forest-admin-logo.webp'
import StripeLogo from '../../assets/images/stripe-logo.png'
import CrispLogo from '../../assets/images/crisp-logo.svg'
import GoogleAnalyticsLogo from '../../assets/images/google-analytics-logo.png'
import logo from '../../assets/images/logo.svg';

const SERVICES = [
	{
		name: 'Forest Admin',
		description: 'Gestion des données, mise en maintenance',
		image: ForestAdminLogo,
		link: 'https://app.forestadmin.com/Karesport/Production/Operations/dashboard'
	},
	{
		name: 'Stripe',
		description: 'Gestion des abonnements et des paiements',
		image: StripeLogo,
		link: 'https://dashboard.stripe.com/dashboard'
	},
	{
		name: 'Crisp',
		description: 'Gestion des demandes de contact',
		image: CrispLogo,
		link: 'https://app.crisp.chat/website/9a8b1482-0b9f-404c-b51c-f29600bea666/inbox/'
	},
	{
		name: 'Google Analytics',
		description: 'Analyse de données utilisateurs',
		image: GoogleAnalyticsLogo,
		link: 'https://analytics.google.com/analytics/web/#/p274212120/reports/defaulthome'
	}
]

const Administration = (props) => {
	const [isAdminConnected, setIsAdminConnected] = useState(false)
	const [adminPassword, setAdminPassword] = useState('')

	useEffect(() => {
		_onPageLoad()
	}, [])

	const _handleAdminPasswordInputChange = (e) => {
		const value = e.target.value

		setAdminPassword(value)

		if (value) {
			api.post('/globalSettings/checkAdminPassword', {
				password: value
			}).then(response => {
				setIsAdminConnected(response.data.success)
				if (response.data.success) {
					document.cookie = `adminPassword=${value}`;
				}
			}).catch(e => {})
		}
	}
	
	const _onPageLoad = async () => {
		setIsAdminConnected(await Utils.isAdminConnected())
	}

	return (
		<>
			{ isAdminConnected ?
				<section className={styles.container}>
					<h1 className={styles.title}>Services d'administration</h1>
					<div className={styles.servicesContainer}>
						{SERVICES.map(service => (
							<ServiceCard {...service} key={service.name} />
						))}
					</div>
				</section>
				:
				<section className={styles.connectionSection}>
					<div className={`${styles.container} ${styles.connectionContainer}`}>
						<img src={logo} alt="Logo de Karesport" className={styles.karesportLogo} />
						<h1 className={styles.title}>Accès réservé</h1>
						<h2 className={styles.message}>Cette page est réservée aux administrateurs de Karesport.</h2>

						<div className={styles.inputContainer}>
							<Input
								value={adminPassword}
								onChange={_handleAdminPasswordInputChange}
								type="password"
								placeholder="Accès administrateur"
							/>
						</div>
					</div>
				</section>
			}
		</>
	)
}

export default Administration