/**
 * All navigation links to display in the navbar
 */
export const NAVIGATION = [
	{
		name: 'Sports',
		link: '/sports',
		active: false
	},
	{
		name: 'GlobalPrev',
		link: '/globalprev',
		active: true
	},
	{
		name: 'Équipe',
		link: '/team',
		active: false
	}
];
