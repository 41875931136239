import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faCog } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../contexts/AuthContext';
import { useAppState } from '../../contexts/StateContext';
import { api } from '../../config/api';
import { useRedirect } from '../../hooks/useRedirect';

import UserInfo from './UserInfo/UserInfo';
import Subscription from './Subscription/Subscription';
import Button from '../../design-system/Button/Button';
import PaymentCard from '../../components/PaymentCard/PaymentCard';
import Spinner from '../../design-system/Spinner/Spinner';

import styles from './Account.module.scss';

const component = (props) => {
	const [cards, setCards] = useState([]);
	const [loadingPortal, setLoadingPortal] = useState(false);
	const [cardsLoading, setCardsLoading] = useState(true);

	const { auth } = useAuth();
	const appState = useAppState();
	const redirect = useRedirect();

	const user = auth.getUser();

	const redirectToUserPortail = () => {
		setLoadingPortal(true);
		api.post('/users/create-stripe-portal-session', {
			fromURL: document.location.href
		})
        .then(response => {
			document.location.href = response.data;
        }).catch(error => {})
	}

	const redirectToPassword = () => {
		redirect.redirectTo(`/password/reset/account`);
	}

    useEffect(() => {
        api.get('/users/payment-cards/all')
        .then(response => {
			setCards(response.data);
			setCardsLoading(false);
        }).catch(error => {
			setCardsLoading(false);
		})
    }, [])

	return (
		<section className={styles.account}>
			{ user ? 
				<>
					<header>
						<h3>Mon compte</h3>
						{ user && <h1>{`${user.firstname} ${user.lastname}`}</h1> }
					</header>

					<div className={styles.container}>
						<div className={styles.mainSection}>
							<div className={styles.sectionCard}>
								<h1 className={styles.title}>Mes informations</h1>

								<UserInfo label="Prénom" value={user.firstname}/>
								<UserInfo label="Nom" value={user.lastname}/>
								<UserInfo label="Email" value={user.email}/>
								
								{/* <div className={styles.editProfileButtonWrapper}>
									<Button variant="main">
										<FontAwesomeIcon icon={faEdit} style={{ marginRight: '1em' }}/>
										<span className={styles.hideBeforeLarge}>Modifier les informations</span>
										<span className={styles.showBeforeLarge}>Modifier</span>
									</Button>
								</div> */}

								<div className={styles.changePasswordButtonWrapper}>
									<Button variant="main-outline" onClick={redirectToPassword}>
										<FontAwesomeIcon icon={faKey} style={{ marginRight: '1em' }}/>
										<span className={styles.hideBeforeLarge}>Modifier le mot de passe</span>
										<span className={styles.showBeforeLarge}>Mot de passe</span>
									</Button>
								</div>
							</div>

							<div className={styles.sectionCard}>
								<h1 className={styles.title}>Mes abonnements</h1>

								{ user.subscriptions.length ?
									<div>
									{ user.subscriptions.map((subscription, i) => (
										<Subscription
											key={`subscription${i}`}
											subscriptionId={subscription.subscriptionId}
										/>
									)) }

									<h2 className={styles.subtitle}>Moyens de paiment</h2>
									{ cards.length ?
									<>
										{ cards.map((card, i) => (
											<PaymentCard
												key={`payment-card${i}`}
												card={card}
											/>
										))}
									</>
									: cardsLoading && <Spinner center size="small"/>
									}

										<div className={styles.stripePortalButtonWrapper}>
											<Button
												variant="main"
												onClick={() => redirectToUserPortail()}
												className={styles.stripePortalButtonLg}
												disabled={loadingPortal}
											>
												<FontAwesomeIcon icon={faCog} style={{ marginRight: '1em' }}/>
												Gérer<span className={styles.hideBeforeLarge}> mes abonnements</span>
											</Button>
										</div>
									</div>
								:
									<div className={styles.noSubscription}>
										<div className={styles.noSubscriptionMessage}>Aucun abonnement</div>
										<Button variant="main" onClick={() => appState.modals.subscribe.open()}>
											S'abonner
										</Button>
									</div>
								}
							</div>
						</div>
					</div>
				</>
				:
				<div className={styles.nonLogged}>
					<div className={styles.content}>
						<div>Vous n'êtes pas connecté</div>
						<div className={styles.actions}>
							<Button variant="main" onClick={() => appState.modals.login.open()}>
								Se connecter
							</Button>
							<Button variant="main-outline" onClick={() => appState.modals.register.open()}>
								S'inscire
							</Button>
						</div>
					</div>
				</div>
			}
		</section>
	);
}

export default component;