import React from 'react';
import { NavLink } from 'react-router-dom';

const NavLinkScrollTop = (props) => (
	<NavLink
		className={props.className}
		activeClassName={props.activeClassName}
		to={props.to}
		onClick={() => window.scrollTo({ top: 0 })}>
		{props.children}
	</NavLink>
);

export default NavLinkScrollTop;