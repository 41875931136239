const axios = require('axios');

export const api = axios.create({
  baseURL: 'https://api.karesport.com',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${window.localStorage.getItem('token')}`
  },
});

export const updateApiToken = token => {
  api.defaults.headers.Authorization = `Bearer ${token}`
}