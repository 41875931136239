import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import ReactHtmlParser from 'react-html-parser';

import Modal from '../../../design-system/Modal/Modal';
import Icon from '../../../design-system/Icon/Icon';
import ExerciseList from '../../ExerciseList/ExerciseList';

import styles from './ExerciseModal.module.scss';
import { api } from '../../../config/api';

const ExerciseModal = (props) => {
	const RELATED_EXERCICES_LIMIT = 4;

	const [isLoading, setIsLoading] = useState(true)
	const [exercise, setExercise] = useState(null);
	const [relatedExercises, setRelatedExercises] = useState([]);

	useEffect(() => {
		if (props.show) {
			const id = parseInt(props.exerciseId);

			if (!id) return;
			
			setIsLoading(true)
			api.get(`/${props.type === 'sports' ? 'sport_exercises' : 'global_prev_exercises'}/${id}`)
				.then(response => {
					setExercise(response.data);
					setIsLoading(false)

					api.get(`/${props.type === 'sports' ? 'sport_exercises' : 'global_prev_exercises'}/${id}/related/${RELATED_EXERCICES_LIMIT}`)
						.then(response => {
							setRelatedExercises(response.data.slice(0, RELATED_EXERCICES_LIMIT));
						})
						.catch(e => {
							setRelatedExercises([]);
						})
				})
		}
		else {
			setExercise(null);
			setRelatedExercises([]);
		}
	}, [props.exerciseId, props.type, props.show])

	return (
		<>
			{(props.show && exercise && !isLoading) ?
				<Modal
					onClose={props.onClose}
					show={props.show}
					size="large"
					displayIcon={false}
					additionalClass={styles.additionalModalCss}
				>
					<header className={styles.header}>
						<div className={styles.closeModal} onClick={props.onClose}>
							<FontAwesomeIcon icon={faTimes} />
						</div>
						<h3>
							{props.type === "sports" ?
								<>
									{exercise.stage?.sport?.title}
									<FontAwesomeIcon
										className={styles.chevronIcon}
										icon={faChevronRight}
										size="xs" />
									{exercise.stage?.title}
								</>
								:
								<>
									{exercise.body_part?.name}
								</>
							}
						</h3>
						<h1>{exercise.title}</h1>
						<div className={styles.duration}>
							<Icon icon="stopwatch" size="20px" />
							<span>{exercise.video?.duration_in_minutes}mn</span>
						</div>
					</header>

					<video className={styles.exerciseVideo} controls>
						<source src={exercise.video?.file} type="video/mp4" />
						<source src={exercise.video?.file} type="video/webm" />
						<source src={exercise.video?.file} type="video/ogg" />
						Votre navigateur ne supporte pas ce type de vidéos.
					</video> 

					<div className={styles.body}>
						{exercise.content &&
							<section className={styles.content}>
								{ ReactHtmlParser(exercise.content) }
							</section>
						}
					</div>

					{relatedExercises.length !== 0 &&
						<footer className={styles.footer}>
							<h1>Autres vidéos</h1>
							<div className={styles.otherVideos}>
								<ExerciseList
									exercises={relatedExercises}
								/>
							</div>
						</footer>
					}
				</Modal> : null
			}
		</>
	);
}

export default ExerciseModal;