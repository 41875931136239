import { useHistory } from 'react-router-dom';

export function useRedirect(friendID) {
	const history = useHistory();

	const redirect = {
		redirectTo: (to) => {
			history.push(to);
			window.scrollTo({ top: 0 })
		}
	};

	return redirect;
}
