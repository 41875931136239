import React from "react";
import styles from "./RadioButton.module.scss";

const RadioButton = (props) => {
	return (
		<div className={`${styles.radioButton} ${props.active ? styles.active : ''} ${props.variant ? styles[props.variant] : styles['classic']}`}>
			<div className={styles.center} />
		</div>
	);
};

export default RadioButton;