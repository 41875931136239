import React from 'react';

import styles from './UserInfo.module.scss';

const UserInfo = (props) => {
  return (
      <div className={styles.userInfo}>
          <div className={styles.label}>{props.label}</div>
          <div className={styles.value}>{props.value}</div>
      </div>
  );
}

export default UserInfo;