import React from 'react';

import styles from './Spinner.module.scss';

const Loader = (props) => {
  const className = `${styles.spinner} ${props.center ? styles.center : null} ${props.size ? styles[props.size] : styles['medium']}`;
  return (
    <div className={className} style={props.style ? props.style : null}>
      <span></span>
    </div>
  );
}

export default Loader;