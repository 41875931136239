import { api } from './config/api'

const Utils = {
	toKebabCase: string => {
		string = string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
		string = string.toLowerCase().replace(/ /g, '-');
		return string;
	},

	formatPrice: price => price % 1 !== 0 ? price.toFixed(2) : price,

	isAdminConnected: async () => {
		const cookies = Utils.getCookies();

		return await api.post('/globalSettings/checkAdminPassword', {
			password: cookies.adminPassword
		}).then(response => {
			return response.data.success ?? false
		}).catch(e => false)
	},

	getCookies: () => {
		return Object.assign(...document.cookie.replace(/\s/g, '').split(';').map(str => {
			const cookie = {};
			cookie[str.split('=')[0]] = str.split('=')[1];
			return cookie;
		}));
	}
}

export default Utils;