import React from 'react';

import exerciseIconLight from "../../assets/icons/exercise_light.svg";
import exerciseIconDark from "../../assets/icons/exercise_dark.svg";
import creditCardIconLight from "../../assets/icons/credit_card_light.svg";
import creditCardIconDark from "../../assets/icons/credit_card_dark.svg";
import lockIconLight from "../../assets/icons/lock_light.svg";
import lockIconDark from "../../assets/icons/lock_dark.svg";
import playIcon from "../../assets/icons/play.svg";
import stopwatchIcon from "../../assets/icons/stopwatch.svg";
import styles from './Icon.module.scss';

const Icon = (props) => {
	const ICONS = {
		dark: {
			'exercise': exerciseIconDark,
			'credit-card': creditCardIconDark,
			'lock': lockIconDark,
		},
		light: {
			'exercise': exerciseIconLight,
			'credit-card': creditCardIconLight,
			'lock': lockIconLight,
		},
		default: {
			'exercise': exerciseIconLight,
			'credit-card': creditCardIconLight,
			'lock': lockIconLight,
			'play': playIcon,
			'stopwatch': stopwatchIcon,
		}
	}

	const type = ICONS[props.type] ? props.type : 'default';
	const source = ICONS[type][props.icon];

	let style = null;
	if (props.size) {
		style = { width: props.size, height: props.size };
	}

	return (
		<img
			alt="icon"
			className={styles.icon}
			style={style}
			src={source}
		/>
	);
}

export default Icon;