import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { api } from '../../config/api';
import Spinner from '../../design-system/Spinner/Spinner';

import styles from './CGV.module.scss';

const CGV = () => {
    const [cgv, setCgv] = useState(null);
    const [privacyPolicy, setPrivacyPolicy] = useState(null);
    const [legalNotice, setLegalNotice] = useState(null);

    const [cgvLoading, setCgvLoading] = useState(true);
    const [privacyPolicyLoading, setPrivacyPolicyLoading] = useState(true);
    const [legalNoticeLoading, setLegalNoticeLoading] = useState(true);

    useEffect(() => {
        api.get('/globalSettings/cgv').then(response => {
            setCgv(response.data);
            setCgvLoading(false);
        })

        api.get('/globalSettings/privacyPolicy').then(response => {
            setPrivacyPolicy(response.data);
            setPrivacyPolicyLoading(false);
        })

        api.get('/globalSettings/legalNotice').then(response => {
            setLegalNotice(response.data);
            setLegalNoticeLoading(false);
        })
    }, []);

    return (
        <>
            <header className={styles.header}>
                <div className={styles.container}>
                    <h1 className={styles.title} id="Conditions_générales_de_vente">Conditions générales de vente</h1>
                </div>
            </header>

            <div className={styles.container}>
                <section className={styles.cgv}>
                    {cgvLoading ? <Spinner center/> : ReactHtmlParser(cgv)}
                </section>
            </div>

            <header className={styles.header}>
                <div className={styles.container}>
                    <h1 className={styles.title} id="Politique_de_confidentialité">Politique de confidentialité</h1>
                </div>
            </header>

            <div className={styles.container}>
                <section className={styles.privacyPolicy}>
                    {privacyPolicyLoading ? <Spinner center/> : ReactHtmlParser(privacyPolicy)}
                </section>
            </div>

            <header className={styles.header}>
                <div className={styles.container}>
                    <h1 className={styles.title} id="Mentions_légales">Mentions légales</h1>
                </div>
            </header>

            <div className={styles.container}>
                <section className={styles.legalNotice}>
                    {legalNoticeLoading ? <Spinner center/> : ReactHtmlParser(legalNotice)}
                </section>
            </div>
        </>
    );
}

export default CGV;