import React, { useEffect, useState } from "react";
import ReactHtmlParser from 'react-html-parser';

import PreventionCategoryCard from "../../components/PreventionCategoryCard/PreventionCategoryCard";
import { api } from "../../config/api";
import Spinner from '../../design-system/Spinner/Spinner';

import styles from "./GlobalPrev.module.scss";

const GlobalPrev = (props) => {
	const [categories, setCategories] = useState([]);
	const [globalPrevPresentation, setGlobalPrevPresentation] = useState([]);
	const [areCategoriesLoading, setAreCategoriesLoading] = useState(true);

	useEffect(() => {
		api.get('/globalSettings/globalPrevPresentation').then(response => {
            setGlobalPrevPresentation(response.data);
        }).catch(() => {})

		api.get(`/globalPrevCategories`)
		.then(response => {
			setCategories(response.data);
			setAreCategoriesLoading(false);
		})
		.catch(() => {
			setAreCategoriesLoading(false);
		})
	}, []);

	return (
		<>
			<div className={styles.backgroundBanner}></div>
			<div className={styles.container}>
				<div className={styles.globalPrev}>
					<div className={styles.header}>
						<h1 className={styles.title}>GlobalPrev</h1>
						{ globalPrevPresentation &&
							<p className={styles.subtitle}>{ ReactHtmlParser(globalPrevPresentation) }</p>
						}
					</div>

					<div className={styles.categoriesContainer}>
						<h6>Choisissez une catégorie pour afficher nos articles</h6>
						<div className={styles.categories}>
							{areCategoriesLoading ? <Spinner center size="large" style={{ marginTop: '10em' }}/> :
							<>
								<PreventionCategoryCard category="exercises" />
							
								{Object.keys(categories).map(key => {
									return (
										<PreventionCategoryCard
											key={key}
											category={categories[key]}
										/>
									);
								})}
							</>
							}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default GlobalPrev;