import React from 'react';

import { useRedirect } from '../../hooks/useRedirect';
import Utils from '../../utils';

import styles from './SportCard.module.scss';

const SportCard = (props) => {
	const redirect = useRedirect();

	const onClick = () => {
		redirect.redirectTo(`/sports/${props.sport.id}`);
	}

	return (
		<div className={styles.sport} onClick={onClick}>
			<div className={styles.background} style={{ backgroundImage: `url(${props.sport.image})` }}></div>
			<div className={styles.contentContainer}>
				<div className={styles.content}>
					<p className={styles.sportTitle}>{props.sport.title}</p>
				</div>
			</div>
		</div>
	);
};

export default SportCard;