import React, { useEffect, useState } from 'react';
import { api } from '../../../config/api';
import Utils from '../../../utils';
import Spinner from '../../../design-system/Spinner/Spinner';

import styles from './Subscription.module.scss';

const Subscription = (props) => {
    const [subscription, setSubscription] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const fadeIn = {
		transform: props.show ? 'translateY(0)' : 'translateY(-150vh)',
		opacity: props.show ? 1 : 0
	};

    const states = {
        active: 'Actif',
        past_due: 'Problème de paiement',
        unpaid: 'Terminé (échec paiement)',
        canceled: 'Annulé',
        incomplete: 'Problème de paiement',
        incomplete_expired: 'Terminé (échec paiement)',
        trialing: "À l'essai"
    }

    useEffect(() => {
        api.get(`/users/subscription/${props.subscriptionId}`)
        .then(response => {
            if (response.data.cancel_at) {
                response.data.cancel_at = new Date(response.data.cancel_at);
            }
            setSubscription(response.data);
            setIsLoading(false);
        })
        .catch(error => {
            setIsLoading(false);
        })
    }, []);

    return (
        isLoading ? <Spinner center size="medium"/> : subscription &&
        <article className={styles.subscription}>
            <h1 className={styles.title}>{subscription.product.name}</h1>
            <p>{subscription.product.description}</p>
            <div className={styles.price}>{Utils.formatPrice(subscription.price.amount)}{subscription.price.currency} / {subscription.price.period}</div>
            <span className={`${styles.status} ${styles[subscription.status]}`}>
                { states[subscription.status] }
                { subscription.status === 'active' && subscription.cancel_at ?
                    ` jusqu'au ${subscription.cancel_at.toLocaleDateString()}` : null
                }
            </span>
        </article>
    );
}

export default Subscription;