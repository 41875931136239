import React, { useState, useEffect } from "react";

import StageCard from '../../components/StageCard/StageCard';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import StagePreviewCard from "../../components/StagePreviewCard/StagePreviewCard";
import { api } from "../../config/api";

import styles from "./Sport.module.scss";

const Sport = (props) => {
	const sportId = parseInt(props.match.params.sport);
	const [sport, setSport] = useState(null);
	const [stages, setStages] = useState([]);
	const [numberOfExercises, setNumberOfExercises] = useState(0);

	useEffect(() => {
		api.get(`/sports/${sportId}`)
			.then(response => {
				setSport(response.data);
			})

		api.get(`/sports/${sportId}/stages`)
			.then(response => {
				response.data = response.data.map(stage => {
					stage.exercises = [];
					return stage;
				});
				setStages(response.data);
			})
		
		api.get(`/sports/${sportId}/numberOfExercises`)
			.then(response => {
				setNumberOfExercises(response.data);
			})
	}, []);

	if (!sport) return (<></>);

	return (
		<>
			<header className={styles.header}>
				<div className={styles.container}>
					<Breadcrumb
						type="light"
						path={[
							{ name: 'Sports', link: '/sports' },
							{ name: sport.title },
						]}
					/>
					<div className={styles.headerText}>
						<h1 className={styles.title}>{sport.title}</h1>
						<p className={styles.subtitle}>{numberOfExercises} séances disponibles</p>
					</div>
				</div>
			</header>

			<div className={styles.backgroundBanner}></div>
			<div className={styles.container}>
				<section className={styles.stagesContainer}>
					{stages.map((stage, i) => {
						return (
							<StageCard
								stage={stage}
								sport={sport}
								key={`stageCard${i}`}
							/>
						);
					})}
				</section>
			</div>

			<div className={`${styles.container} ${styles.stagePreviewsContainer}`}>
				<section>
					{stages.map((stage, i) => {
						stage.sport = sport;
						return (
							<div className={styles.exerciseCategoryContainer} key={`stage${i}`}>
								<StagePreviewCard
									stage={stage}
									type="sport"
								/>
							</div>
						);
					})}
				</section>
			</div>
		</>
	);
}

export default Sport;