import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import styles from './Alert.module.scss';

const Alert = (props) => {
	const alertIcon = () => {
		switch (props.type) {
			case 'success':
				return faInfoCircle;
			case 'error':
				return faExclamationTriangle;
			default:
				return null;
		}
	}

	return (
		<div className={`${styles.alert} ${styles[props.type]}`}>
			{alertIcon() ? <FontAwesomeIcon icon={alertIcon()} /> : null}
			{props.message}
		</div>
	)
}

export default Alert;