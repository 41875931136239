import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Icon from '../../design-system/Icon/Icon';
import Button from '../../design-system/Button/Button';
import ArticleList from '../../components/ArticleList/ArticleList';
import OtherPreventionCategories from '../../components/OtherPreventionCategories/OtherPreventionCategories';

import tempImg from '../../assets/images/temp/temp2.png';
import styles from './Article.module.scss';
import { useAppState } from '../../contexts/StateContext';
import { api } from '../../config/api';
import { useAuth } from '../../contexts/AuthContext';
import Utils from '../../utils';
import ArticleCarousel from '../../components/ArticleCarousel/ArticleCarousel';

const Article = (props) => {
	const MORE_ARTICLES_SIZE = 10;
	const MORE_CATEGORIES_SIZE = 3;

	const { auth } = useAuth();

	const [article, setArticle] = useState(null);
	const [relatedActicles, setRelatedArticles] = useState([]);
	const [otherCategories, setOtherCategories] = useState([]);

	const appState = useAppState();

	useEffect(() => {
		api.get(`articles/${props.match.params.id}`)
			.then(response => {
				setArticle(response.data);
			})
			.catch(e => {
				// TODO: Page introuvable
			})

		api.get(`articles/${props.match.params.id}/relatedArticles`)
		.then(response => {
			setRelatedArticles(response.data.slice(0, MORE_ARTICLES_SIZE));
		})
	}, []);

	useEffect(() => {
		article && api.get(`globalPrevCategories/${article.category.id}/relatedCategories`)
		.then(response => {
			setOtherCategories(response.data.slice(0, MORE_CATEGORIES_SIZE));
		})
		console.log(article)
	}, [article])

	return (
		article && <div className={styles.article}>
			<div className={styles.container}>
				<header className={styles.header}>
					<Breadcrumb
						type="light"
						path={[
							{ name: 'GlobalPrev', link: '/globalprev' },
							{ name: article.category.title, link: `/globalprev/${article.category.id}` },
							{ name: article.title },
						]}
					/>

					<div className={styles.headerText}>
						<div className={styles.categoryContainer}>
							<img
								src={article.category.icon}
								width="32"
								height="32"
								alt={`Icône ${article.category.title}`}
							/>
							<div className={styles.categoryTitle}>{article.category.title}</div>
						</div>
						<div className={styles.title}>{article.title}</div>
					</div>

					<div className={styles.headerImage} style={{ backgroundImage: `url(${article.image})` }}></div>
				</header>

				<div className={styles.articleContent}>
					{ReactHtmlParser(article.content)}
				</div>
			</div>

			{relatedActicles.length !== 0 &&
				<div className={styles.moreArticles}>
					<div className={styles.container}>
						{auth.isSubscribed() ? null :
							<div className={styles.unlockAll}>
								<div>Débloquez tous nos articles et vidéos.</div>
								<Button
									variant="main-invert-sm"
									onClick={() => appState.modals.subscribe.open()}
								>
									S'abonner
								</Button>
							</div>
						}
	
						<div className={styles.moreArticlesContainer}>
							<div className={styles.moreArticlesTitle}>Autres articles pour "{article.category.title}"</div>
							<div className={styles.articlesContainer}>
								<ArticleCarousel
									articles={relatedActicles}
								/>
							</div>
						</div>
					</div>
				</div>
			}

			{ otherCategories.length !== 0 &&
				<div className={styles.container}>
					<OtherPreventionCategories categories={otherCategories} itemSize="small" />
				</div>
			}
		</div>
	);
}

export default Article;