import React from "react";

import { useRedirect } from '../../hooks/useRedirect';
import styles from "./Button.module.scss";

const Button = (props) => {
	const redirect = useRedirect();

	let onClick = null;
	if (props.link) onClick = () => redirect.redirectTo(props.link);
	if (props.onClick) onClick = props.onClick;

	return (
		<button className={styles[props.variant]} onClick={onClick} type={props.type} disabled={props.disabled}>
			{props.children}
		</button>
	);
};

export default Button;