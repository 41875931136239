import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { faTimes, faBars } from '@fortawesome/free-solid-svg-icons';

import Navbar from './Navbar/Navbar';
import Footer from './Footer/Footer';
import SideNav from './SideNav/SideNav';
import LoginModal from '../components/Modals/LoginModal/LoginModal';
import RegisterModal from '../components/Modals/RegisterModal/RegisterModal';
import SubscribeModal from '../components/Modals/SubscribeModal/SubscribeModal';
import ForgotPasswordModal from '../components/Modals/ForgotPasswordModal/ForgotPasswordModal';
import CongratulationModal from '../components/Modals/CongratulationModal/CongratulationModal';
import ExerciseModal from '../components/Modals/ExerciseModal/ExerciseModal';
import { useAppState } from '../contexts/StateContext';

import styles from './Layout.module.scss';

const Layout = (props) => {
	const appState = useAppState();
	const modals = appState.modals;

	const location = useLocation();

	const [openSideNav, setOpenSideNav] = useState(false);

	return (
		<>
			<LoginModal
				onClose={modals.login.close}
				show={modals.login.isOpen}
			/>
			<RegisterModal
				onClose={modals.register.close}
				show={modals.register.isOpen}
			/>
			<ForgotPasswordModal
				onClose={modals.forgotPassword.close}
				show={modals.forgotPassword.isOpen}
			/>
			<SubscribeModal
				onClose={modals.subscribe.close}
				show={modals.subscribe.isOpen}
				selectedFormula={modals.subscribe.selectedFormula}
			/>
			<CongratulationModal
				onClose={modals.congratulations.close}
				show={modals.congratulations.isOpen}
				selectedFormula={modals.congratulations.selectedFormula}
			/>
			<ExerciseModal
				onClose={modals.sportExercise.close}
				show={modals.sportExercise.isOpen}
				exerciseId={modals.sportExercise.selectedExercise ? modals.sportExercise.selectedExercise : null}
				type={modals.sportExercise.selectedType || null}
			/>

			<Navbar
				onBurgerClick={() => {
					setOpenSideNav(!openSideNav);
					return !openSideNav;
				}}
				sideNavTogglerIcon={openSideNav ? faTimes : faBars}
				home={location.pathname === '/'}
			/>
			<SideNav show={openSideNav} onClose={() => setOpenSideNav(false)} />
			<main className={`${styles.main} ${location.pathname === '/' ? styles.home : ''}`}>
				{props.children}
			</main>
			<Footer />
		</>
	);
}

export default Layout;