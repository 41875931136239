import React from 'react'

import styles from './ServiceCard.module.scss';

const ServiceCard = (props) => {
	return (
		<a className={styles.card} href={props.link} target="blank">
			<img src={props.image} alt={`Logo ${props.name}`} className={styles.logo} />
			<h1 className={styles.name}>{props.name}</h1>
			<div className={styles.description}>{props.description}</div>
		</a>
	)
}

export default ServiceCard