import React from 'react';

import PreventionCategoryCard from "../../components/PreventionCategoryCard/PreventionCategoryCard";

import styles from './OtherPreventionCategories.module.scss';

const OtherPreventionCategories = (props) => {
	return (
		<div className={styles.otherCategories}>
			<div className={styles.title}>Autres catégories GlobalPrev</div>
			<div className={styles.categories}>
				{props.categories && Object.keys(props.categories).map(key => {
					return (
						key !== props.exclude ?
							< PreventionCategoryCard
								key={key}
								category={props.categories[key]}
								size={props.itemSize ? props.itemSize : "large"}
							/> : null
					);
				})}
			</div>
		</div>
	);
}

export default OtherPreventionCategories;