import React, { createContext, useState, useContext, useEffect } from 'react';
import { useRedirect } from '../hooks/useRedirect';
import { api, updateApiToken } from '../config/api';
var jwt = require('jsonwebtoken');

export const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
	const redirect = useRedirect();

	const [user, setUser] = useState(null);

	const updateUserFromToken = () => {
		setUser(decodeToken());
	}

	const refreshUser = callback => {
		const tokenData = decodeToken();

		if (tokenData) {
			getFreshToken(tokenData.refresh_token, token => {
				if (token) {
					window.localStorage.setItem('token', token);
					updateApiToken(token);
					updateUserFromToken();

					if (callback) callback();
				}
				else {
					auth.logout();
				}
			});
		}
	}

	const launchAutomaticUserRefresh = () => {
		const tokenData = decodeToken();

		if (tokenData) {
			setTimeout(() => {
				refreshUser(() => {
					launchAutomaticUserRefresh();
				});
			}, tokenData.lifetime - 10000);
		}
	}

	const decodeToken = () => {
		const token = window.localStorage.getItem('token');

		if (token) {
			const data = {
				...jwt.decode(token),
				token,
			}
	
			const now = new Date();
			const expirationDate = new Date(data.exp * 1000);
			data.lifetime = expirationDate.getTime() - now.getTime();
			data.expired = expirationDate <= now;
	
			return data;
		}

		return null;
	}

	const getFreshToken = (refresh_token, callback) => {
		api.post('/users/token', { refresh_token })
		.then(response => {
			if (callback) callback(response.data);
		}).catch(error => {
			if (callback) callback(null);
		})
	}

	const auth = {
		getUser: () => user,

		setUser: user => {
			setUser(user);
		},

		isLogged: () => user !== null,

		isSubscribed: () => auth.isLogged() && user.subscriptions && user.subscriptions.filter(subscription => subscription.active).length !== 0,

		isSubscribedTo: productId => {
			if (!auth.isLogged() || !user.subscriptions)
				return false;

			for (const subscription of user.subscriptions)
				if (subscription.active && subscription.productId === productId) return true;

			return false;
		},

		login: token => {
			window.localStorage.setItem('token', token);
			updateApiToken(token);
			updateUserFromToken();
			launchAutomaticUserRefresh();
		},

		logout: () => {
			setUser(null);
			window.localStorage.removeItem('token');
			redirect.redirectTo('/');
		},

		canOpenArticle: article => {
			// Always true if article is public
			if (article.privacy === 'public')
				return true;

			// If article is not public, user must be logged
			if (!auth.isLogged())
				return false;

			// If article is protected only by authentification, return true
			// (user is logged or he would not have pass the previous test)
			if (article.privacy === 'authenticated')
				return true;

			// If article is not free, user must have full access to globalprev
			if (article.privacy === 'subscribed' && auth.hasFullAccessTo('globalprev'))
				return true;

			return false;
		},

		canOpenGlobalPrevExercise: exercise => {
			// Always true if exercise is public
			if (exercise.privacy === 'public')
				return true;

			// If exercise is not public, user must be logged
			if (!auth.isLogged())
				return false;

			// If exercise is protected only by authentification, return true
			// (user is logged or he would not have pass the previous test)
			if (exercise.privacy === 'authenticated')
				return true;

			// If exercise is not free, user must have full access to globalprev section
			if (exercise.privacy === 'subscribed' && auth.hasFullAccessTo('globalprev')) {
				return true;
			}

			return false;
		},

		canOpenSportExercise: exercise => {
			// Always true if exercise is public
			if (exercise.privacy === 'public')
				return true;

			// If exercise is not public, user must be logged
			if (!auth.isLogged())
				return false;

			// If exercise is protected only by authentification, return true
			// (user is logged or he would not have pass the previous test)
			if (exercise.privacy === 'authenticated')
				return true;

			// If exercise is not free, user must have full access to sports section
			if (exercise.privacy === 'subscribed' && auth.hasFullAccessTo('sports')) {
				return true;
			}

			return false;
		},

		hasFullAccessTo: section => {
			if (!auth.isSubscribed()) return false;

			for (const subscription of user.subscriptions)
				if (subscription.active && subscription.access === section) return true;

			return false;
		}
	}

	useEffect(() => {
		const tokenData = decodeToken();
		if (tokenData && tokenData.expired)
			refreshUser();
		else
			updateUserFromToken();

		launchAutomaticUserRefresh();
	}, []);

	const state = {
		auth
	};

	return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};
