import React, { useState, useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import Modal from '../../../design-system/Modal/Modal';
import SubscriptionFormula from './SubscriptionFormula/SubscriptionFormula';
import CheckoutForm from './CheckoutForm/CheckoutForm';
import { api } from '../../../config/api';
import { APP } from '../../../config/config';
import { useRedirect } from '../../../hooks/useRedirect';

import styles from './SubscribeModal.module.scss';

const SubscribeModal = (props) => {
	const [formulas, setFormulas] = useState([]);
	const [selectedFormula, setSelectedFormula] = useState(null);

	const redirect = useRedirect();

	const toggleFormula = formula => setSelectedFormula(selectedFormula === formula ? null : formula);

	useEffect(() => {
		api.get('/subscriptions')
			.then(response => {
				setFormulas(response.data);
			})
	}, []);

	useEffect(() => {
		setSelectedFormula(
			formulas.filter(formula => formula.id === props.selectedFormula)[0]
		);
	}, [props.selectedFormula]);
	
	const stripePromise = loadStripe(APP.stripe.publicKey);

	return (
		<Modal
			onClose={props.onClose}
			show={props.show}
			title="Choix de la formule et paiement"
		>
			<h6 className={styles.formAreaLabel}>Choisissez votre formule</h6>
			<div className={styles.subscriptionFormulasContainer}>
				{
					formulas.map((formula, i) => {
						return (
							<div className={styles.formulaContainer} key={`formula${i}`}>
								<SubscriptionFormula
									formula={formula}
									selected={selectedFormula && selectedFormula.name === formula.name ? true : false}
									onClick={() => toggleFormula(formulas[i])}
								/>
							</div>
						)
					})
				}
			</div>

			{ selectedFormula &&
			<>
				<h6 className={styles.formAreaLabel}>Vos informations des paiement</h6>
				<Elements stripe={stripePromise}>
					<CheckoutForm formula={selectedFormula}/>
				</Elements>
			</>
			}
		</Modal>
	);
}

export default SubscribeModal;