import React, {useState, useEffect } from 'react';

import { useRedirect } from '../../hooks/useRedirect';

import logo from '../../assets/images/logo.svg';
import brand from '../../assets/images/brand_text_3x.png';
import styles from './Footer.module.scss';
import { api } from '../../config/api';
import { NAVIGATION } from '../../config/constants';

const Footer = () => {
	const [socialMedias, setSocialMedias] = useState([])

	useEffect(() => {
		api.get('/socialMedias')
			.then(response => {
				setSocialMedias(response.data)
			})
			.catch(e => {})
	}, [])

	return (
		<footer className={styles.footer}>
			<div className={styles.container}>
				<div className={styles.content}>
					<div className={styles.logoBrand}>
						<img className={styles.logo} src={logo} alt="Karesport logo" />
						<img className={styles.brand} src={brand} alt="Karesport brand" />
					</div>
					<div>
						<h6>Site</h6>
						<ul>
							{ NAVIGATION.map(page => (
								<li key={page.link}><a href={page.link}>{ page.name }</a></li>
							)) }
						</ul>
					</div>
					<div>
						<h6>Mentions légales</h6>
						<ul>
							<li><a href="/cgv#Conditions_générales_de_vente">CGV</a></li>
							<li><a href="/cgv#Politique_de_confidentialité">Politique de confidentialité</a></li>
							<li><a href="/cgv#Mentions_légales">Mentions légales</a></li>
						</ul>
					</div>
					{ socialMedias.length !== 0 &&
						<div>
							<h6>Réseaux sociaux</h6>
							<ul>
								{ socialMedias.map(socialMedia => (
									<li key={socialMedia.name}><a href={socialMedia.url} target="blank">{ socialMedia.name }</a></li>
								))}
							</ul>
						</div>
					}
				</div>

				<div className={styles.bottomLinksContainer}>
					Copyright Karesport
					<span> - </span>
					<a
						className={styles.adminLink}
						href="https://www.kamelleon.fr"
					>
						Création Kamelleon
					</a>
					<span> - </span>
					<a
						className={styles.adminLink}
						href="/admin"
					>
						Administration
					</a>
				</div>
			</div>
		</footer >
	);
}

export default Footer;