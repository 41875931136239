import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';

import ExerciseList from '../../components/ExerciseList/ExerciseList';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import ChooseMuscle from '../../components/ChooseMuscle/ChooseMuscle';
import OtherPreventionCategories from '../../components/OtherPreventionCategories/OtherPreventionCategories';
import Spinner from '../../design-system/Spinner/Spinner';

import styles from './ExerciseCategory.module.scss';
import { api } from '../../config/api';

const ExerciseCategory = (props) => {
	const [bodyPart, setBodyPart] = useState(null);
	const [exercises, setExercises] = useState([]);
	const [areExercisesLoading, setAreExercisesLoading] = useState(true);

	useEffect(() => {
		_loadData()
	}, []);

	useEffect(() => {
		setAreExercisesLoading(true)
		setBodyPart(null)
		setExercises([])
		_loadData()
	}, [props.match.params])

	const _loadData = async () => {
		api.get(`/bodyParts/${props.match.params.category}`)
			.then(reponse => {
				setBodyPart(reponse.data);
			})

		api.get(`/bodyParts/${props.match.params.category}/exercises`)
			.then(reponse => {
				setExercises(reponse.data);
				setAreExercisesLoading(false);
			}).catch(() => {
				setAreExercisesLoading(false);
			})
	}

	return bodyPart && (
		<>
			<div className={styles.categoryHeader}>
				<div className={styles.container}>
					<Breadcrumb
						type="light"
						path={[
							{ name: 'GlobalPrev', link: '/globalprev' },
							{ name: 'Exercices', link: '/globalprev/exercises/categories' },
							{ name: bodyPart.name },
						]}
					/>

					<div className={styles.headerText}>
						<p className={styles.title}>{bodyPart.name}</p>
						{ bodyPart.presentation &&
							<section className={styles.presentation}>
								{ ReactHtmlParser(bodyPart.presentation) }
							</section>
						}
						<p className={styles.subtitle}>{exercises.length} exercices disponibles</p>
					</div>
				</div>
			</div>

			<div className={styles.backgroundBanner}></div>
			<div className={styles.container}>
				<div className={styles.exercisesContainer}>
					{areExercisesLoading ?
						<Spinner center size="large" style={{ margin: '2em 0' }}/>
						:
						<ExerciseList exercises={exercises} splitByGrade />
					}
				</div>

				<div className={styles.changeMuscleTitle}>Exercez-vous sur une autre partie</div>
				<ChooseMuscle width="650" />
			</div>
		</>
	);
}

export default ExerciseCategory;