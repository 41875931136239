import React from 'react';
import { useHistory } from 'react-router-dom';

import styles from './Breadcrumb.module.scss';

const Breadcrumb = (props) => {
	const history = useHistory();

	return (
		<div className={props.type === 'light' ? styles.breadcrumbLight : styles.breadcrumbDark}>
			<p>
				{props.path.map((pathItem, i) => {
					const onClick = pathItem.link ? () => history.replace(pathItem.link) : null;
					const isLast = i === props.path.length - 1;

					return (
						<span key={`breadcrumbItem${i}`}>
							<span className={isLast ? '' : styles.clickable} onClick={onClick}>
								{isLast ? <strong>{pathItem.name}</strong> : pathItem.name}
							</span>
							{!isLast ? <span> > </span> : null}
						</span>
					);
				})}
			</p>
		</div>
	);
}

export default Breadcrumb;