import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faUserCircle, faUser } from '@fortawesome/free-solid-svg-icons';

import { useRedirect } from '../../hooks/useRedirect';
import NavLinkScrollTop from '../../components/NavLinkScrollTop/NavLinkScrollTop';
import Button from '../../design-system/Button/Button';
import { useAuth } from '../../contexts/AuthContext';
import Dropdown from '../../design-system/Dropdown/Dropdown';
import { NAVIGATION } from '../../config/constants';
import { useAppState } from '../../contexts/StateContext';


import logo from '../../assets/images/logo.svg';
import brand from '../../assets/images/brand_text.png';
import styles from './Navbar.module.scss';

const Navbar = (props) => {
	const appState = useAppState();
	const redirect = useRedirect();

	const { auth } = useAuth();

	const [transparent, setTransparent] = useState(false);
	let isTransparent = false;

	const [isSideNavOpenned, setIsSideNavOppened] = useState(false);

	let isScrollListenerSet = false;

	const handleScroll = () => {
		if (window.scrollY > 80 && isTransparent === true) {
			setTransparent(false);
			isTransparent = false;
		}
		else if (window.scrollY < 80 && isTransparent === false) {
			setTransparent(true);
			isTransparent = true;
		}
	}

	useEffect(() => {
		if (props.home === true) {
			if (!isScrollListenerSet) {
				window.addEventListener('scroll', handleScroll);
				isScrollListenerSet = true;
			}
		}
		else {
			window.removeEventListener('scroll', handleScroll);
		}
	}, [props.home]);

	return (
		<div className={`${styles.navbar} ${props.home && transparent && !isSideNavOpenned ? styles.transparent : ''}`}>
			<div className={styles.leftGroup}>
				<div className={styles.logoBrandContainer} onClick={() => redirect.redirectTo('/')}>
					<img
						alt="logo"
						className={styles.logo}
						src={logo}
					/>
					<img
						alt="brand-text"
						className={styles.brand}
						src={brand}
					/>
				</div>

				<ul className={styles.navigationButtons}>
					{NAVIGATION.map((navItem, i) => {
						return (
							<li key={`nav${i}`} className={`${styles.navigationButton}`}>
								<NavLinkScrollTop to={navItem.link} activeClassName={styles.active}>
									{navItem.name}
								</NavLinkScrollTop>
								<span className={styles.activeDot}></span>
							</li>
						);
					})}
				</ul>
			</div>

			<div className={styles.rightGroup}>
				{auth.isLogged() ?
					<div className={styles.userDropdownWraper}>
						<Dropdown
							title={auth.getUser().firstname}
							icon={faUserCircle}
							openOn="click"
							items={[
								{ onClick: () => redirect.redirectTo('/account'), name: "Mon compte", icon: <FontAwesomeIcon icon={faUser} /> },
								{ onClick: auth.logout, name: "Déconnexion", icon: <FontAwesomeIcon icon={faSignOutAlt} /> },
							]}
						/>
					</div>
					:
					<Button variant="invisible" onClick={() => appState.modals.login.open()}>Se connecter</Button>
				}
				{auth.isSubscribed() ?
					<span className={styles.premium}>Premium</span>
				:
				<Button
					variant="main-invert-outline-sm"
					onClick={() => appState.modals.subscribe.open()}
				>
					S'abonner
				</Button>
				}
			</div>

			<FontAwesomeIcon
				className={`${styles.sideNavToggler}`}
				icon={props.sideNavTogglerIcon}
				size="lg"
				onClick={() => {
					setIsSideNavOppened(props.onBurgerClick());
				}}
			/>
		</div>
	);
};

export default Navbar;