import React from 'react';

import Modal from '../../../design-system/Modal/Modal';

import styles from './CongratulationModal.module.scss';
import Button from '../../../design-system/Button/Button';

const CongratulationModal = (props) => {
	return (
		<Modal
			onClose={props.onClose}
			show={props.show}
			size="small">
			<h1 className={styles.title}>Félicitations !</h1>
			<p className={styles.message}>Profitez en illimité de nos conseils et vidéos sur la plateforme.</p>

			<Button variant="main" onClick={() => props.onClose()}>Découvrir</Button>
		</Modal>
	);
}

export default CongratulationModal;