import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Layout from './layouts/Layout';
import Home from './pages/Home/Home';
import GlobalPrev from './pages/GlobalPrev/GlobalPrev';
import PreventionCategory from './pages/PreventionCategory/PreventionCategory';
import ExerciseCategory from './pages/ExerciseCategory/ExerciseCategory';
import Article from './pages/Article/Article';
import { AuthProvider } from './contexts/AuthContext';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import Sports from './pages/Sports/Sports';
import Sport from './pages/Sport/Sport';
import SportStage from './pages/SportStage/SportStage';
import { StateProvider } from './contexts/StateContext';
import PreventionExercises from './pages/PreventionExercises/PreventionExercises';
import CGV from './pages/CGV/CGV';
import Account from './pages/Account/Account';
import Team from './pages/Team/Team';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import Maintenance from './pages/Maintenance/Maintenance';
import ServerDown from './pages/ServerDown/ServerDown';
import Administration from './pages/Administration/Administration';

import { api } from './config/api';
import Utils from './utils';

function App() {
	const [appStatus, setAppStatus] = useState(null);

	useEffect(() => {
		_onAppLoad()
	}, [])

	const _onAppLoad = async () => {
		const isAdminConnected = await Utils.isAdminConnected()

		api.get('/globalSettings/status')
            .then(response => {
				const status = response.data

				if (status === 'maintenance') {
					setAppStatus(isAdminConnected ? 'live' : status);
				} else {
					setAppStatus(status);
				}
            })
			.catch(error => {
				setAppStatus('down');
			})
	}

	const _onAdminLoginSuccess = (password) => {
		document.cookie = `adminPassword=${password}`;
		setAppStatus('live');
	}

	return appStatus && (
		<BrowserRouter>
			<AuthProvider>
				<StateProvider>
					<div className="App">
						{ appStatus === 'live' &&
							<Layout>
								<Switch>
									{/* Home */}
									<Route path="/" exact component={Home} />

									{/* GlobalPrev */}
									<Route path="/globalprev" exact component={GlobalPrev} />
									<Route path="/globalprev/:category" exact component={PreventionCategory} />
									<Route path="/globalprev/article/:id" exact component={Article} />
									<Route path="/globalprev/exercises/categories" exact component={PreventionExercises} />
									<Route path="/globalprev/exercises/categories/:category" exact component={ExerciseCategory} />

									{/* Sports */}
									<Route path="/sports" exact component={Sports} />
									<Route path="/sports/:sport" exact component={Sport} />
									<Route path="/sports/:sport/:stage" exact component={SportStage} />

									{/* Password Reset */}
									<Route path="/password/reset/:token" exact component={ResetPassword} />

									{/* CGV */}
									<Route path="/cgv" exact component={CGV} />

									{/* Account */}
									<Route path="/account" exact component={Account} />

									{/* Team */}
									<Route path="/team" exact component={Team} />

									{/* Administration */}
									<Route path='/admin' exact component={Administration} />

									{/* Page not found */}
									<Route component={PageNotFound} />
								</Switch>
							</Layout>
						}

						{ appStatus === 'maintenance' &&
							<Maintenance onAdminLoginSuccess={_onAdminLoginSuccess}/>
						}

						{ appStatus === 'down' &&
							<ServerDown />
						}
					</div>
				</StateProvider>
			</AuthProvider>
		</BrowserRouter>
	);
}

export default App;
