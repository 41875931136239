import React, { useState, useEffect } from 'react';

import Button from '../../design-system/Button/Button';
import ExerciseCarousel from '../ExerciseCarousel/ExerciseCarousel';
import { api } from '../../config/api';
import { BREAKPOINTS } from '../../config/config';

import styles from './StagePreviewCard.module.scss';

const StagePreviewCard = (props) => {
	const NUMBER_OF_EXERCICES = 8;

	const [exercises, setExercises] = useState([]);
	const [nbOfExercises, setNbOfExercises] = useState(0);
	const [exercisesLoading, setExercisesLoading] = useState(true);
	const [numberLoading, setNumberLoading] = useState(true);

	useEffect(() => {
        api.get(`/stages/${props.stage.id}/exercises/${NUMBER_OF_EXERCICES}`)
        .then(response => {
            setExercises(response.data);
			setExercisesLoading(false);
        })

        api.get(`/stages/${props.stage.id}/numberOfExercises`)
        .then(response => {
            setNbOfExercises(response.data);
			setNumberLoading(false);
        })
	}, [])

	return !exercisesLoading && !numberLoading && (
		<div className={styles.stagePreviewCard}>
			<div className={styles.header}>
				<div className={styles.title}>{props.stage.title}</div>
				<div className={styles.numberOfExercises}>
					<span>• </span>{nbOfExercises} séance{nbOfExercises > 1 ? 's' : ''}
				</div>
			</div>

			{nbOfExercises > 0 &&
				<>
					<div className={styles.exerciseList}>
						<ExerciseCarousel
							fadeBorder={window.innerWidth > BREAKPOINTS.sm}
							exercises={exercises}
							type="sports"
						/>
					</div>

					<div className={styles.buttonAllExercisesWrapper}>
						<Button
							variant="main"
							link={`${props.stage.sport.id}/${props.stage.id}`}
						>
							<span className={styles.buttonTextSmall}>Voir tout</span>
							<span className={styles.buttonTextLarge}>Voir tous les exercices pour "{props.stage.title}"</span>
						</Button>
					</div>
				</>
			}
		</div>
	);
}

export default StagePreviewCard;