import React from 'react';

import styles from './Case.module.scss';

const Case = (props) => {
  return (
      <div className={styles.case}>
          <h2>{`Cas ${props.number}`}</h2>
          <h1>{props.title}</h1>
          <p>{props.children}</p>
      </div>
  );
}

export default Case;