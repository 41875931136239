import React, { useEffect, useState } from 'react';

import SportCard from "../../components/SportCard/SportCard";
import { api } from '../../config/api';
import StageCard from '../StageCard/StageCard';

import styles from './OtherSportStages.module.scss';

const OtherSportStages = (props) => {
	const [stages, setStages] = useState([]);
	const [stagesLoading, setStagesLoading] = useState(true);

	useEffect(() => {
		api.get(`/sports/${props.sport.id}/stages`)
		.then(response => {
			response.data = response.data.map(stage => {
				stage.exercises = [];
				return stage;
			});
			setStages(response.data);
			setStagesLoading(false);
		})
	}, [])

	return !stagesLoading && (
		<div className={styles.otherStages}>
			<div className={styles.title}>Autres phases</div>
			<div className={styles.stages}>
				{stages.map((stage, i) => {
					return stage.id !== props.exclude ? (
						<StageCard
							key={`stage${i}`}
							sport={props.sport}
							stage={stage}
						/>
					) : null
				})}
			</div>
		</div>
	);
}

export default OtherSportStages;