import React from 'react';

import styles from './Input.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Input = (props) => {
	return (
		<div className={`${styles.inputGroup} ${props.icon ? styles.withIcon : ''}`}>
			{props.label ?
				<label>{props.label} {props.required ? '*' : ''}</label> : null
			}

			{props.icon ?
				<div className={styles.iconWrapper}>
					<FontAwesomeIcon className={styles.icon} icon={props.icon} />
				</div> : null
			}

			<input
				type={props.type}
				placeholder={props.placeholder}
				min={props.min}
				max={props.max}
				onInput={props.onInput}
				onChange={props.onChange}
				style={props.style}
				value={props.value}
				name={props.name}
				required={props.required}
			/>
			{!props.error && props.message ?
				<small className={styles.message}>{props.message}</small> : null
			}
			{props.error ?
				<small className={styles.error}>{props.error}</small> : null
			}
		</div>
	);
}

export default Input;