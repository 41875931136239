import React, { createContext, useState, useContext } from 'react';
import { useAuth } from './AuthContext';

export const StateContext = createContext();

export const useAppState = () => useContext(StateContext);

export const StateProvider = ({ children }) => {
    const { auth } = useAuth();

    let modalToOpen = window.location.hash.replace('#', '');

	const [openLogin, setOpenLogin] = useState(modalToOpen === 'login');
	const [openForgotPassword, setOpenForgotPassword] = useState(modalToOpen === 'forgot-password');
	const [openRegister, setOpenRegister] = useState(modalToOpen === 'register');
    const [openSubscribe, setOpenSubscribe] = useState(modalToOpen === 'subscribe' && auth.isLogged());
    const [openCongratulations, setOpenCongratulations] = useState(modalToOpen === 'congratulations');
    const [selectedFormula, setSelectedFormula] = useState(null);
    const [openSportExercise, setOpenSportExercise] = useState(false);
    const [selectedSportExercise, setSelectedSportExercise] = useState(null);
    const [selectedExerciseType, setSelectedExerciseType] = useState(null);

    const setOpenSubscribeOrLogin = open => {
        return auth.isLogged() ? setOpenSubscribe(open) : setOpenLogin(open);
    }

    const toggleOpenSubscribeOrLogin = () => {
        return auth.isLogged() ?
            setOpenSubscribe(!openSubscribe) :
            setOpenLogin(!openLogin);
    }

	const modals = {
        login: {
            open: () => setOpenLogin(true),
            close: () => setOpenLogin(false),
            toggle: () => setOpenLogin(!openLogin),
            isOpen: openLogin 
        },
        forgotPassword: {
            open: () => setOpenForgotPassword(true),
            close: () => setOpenForgotPassword(false),
            toggle: () => setOpenForgotPassword(!openForgotPassword),
            isOpen: openForgotPassword 
        },
        register: {
            open: () => setOpenRegister(true),
            close: () => setOpenRegister(false),
            toggle: () => setOpenRegister(!openRegister),
            isOpen: openRegister 
        },
        subscribe: {
            open: () => setOpenSubscribeOrLogin(true),
            close: () => setOpenSubscribeOrLogin(false),
            toggle: toggleOpenSubscribeOrLogin,
            isOpen: openSubscribe,
            selectedFormula,
            setSelectedFormula
        },
        congratulations: {
            open: () => setOpenCongratulations(true),
            close: () => setOpenCongratulations(false),
            toggle: () => setOpenCongratulations(!openCongratulations),
            isOpen: openCongratulations,
        },
        sportExercise: {
            open: () => setOpenSportExercise(true),
            close: () => setOpenSportExercise(false),
            toggle: () => setOpenSportExercise(!openSportExercise),
            isOpen: openSportExercise,
            selectedExercise: selectedSportExercise,
            selectedType: selectedExerciseType,
            setSelectedExercise: (exercise, type) => {
                setSelectedSportExercise(exercise);
                setSelectedExerciseType(type);
            }
        }
	}

	const state = {
        modals
	};

	return <StateContext.Provider value={state}>{children}</StateContext.Provider>;
};
