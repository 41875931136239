import React, { useMemo, Fragment } from 'react';
import Slider from 'react-slick';
import '../../slick.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowLeft, faArrowRight} from '@fortawesome/free-solid-svg-icons';

import { BREAKPOINTS } from '../../config/config';

import styles from './Carousel.module.scss';

const Carousel = (props) => {
    // Number of articles displayed for each breakpoint
    const responsive = [
        { breakpoint: BREAKPOINTS.md, settings: { slidesToShow: props.responsive.sm } },
        { breakpoint: BREAKPOINTS.lg, settings: { slidesToShow: props.responsive.md } },
        { breakpoint: BREAKPOINTS.xl, settings: { slidesToShow: props.responsive.lg } },
        { breakpoint: BREAKPOINTS.xxl, settings: { slidesToShow: props.responsive.xl } },
        { breakpoint: 10000, settings: { slidesToShow: props.responsive.xxl } }
    ]

    const renderPrevArrow = () => {
        return (
            <div>
                <div className={styles.prev}>
                    <div className={styles.arrowContainer}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </div>
                </div>
            </div>
        )
    }

    const renderNextArrow = () => {
        return (
            <div>
                <div className={styles.next}>
                    <div className={styles.arrowContainer}>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </div>
                </div>
            </div>
        )
    }

    return useMemo(() => (
        <div className={`${styles.articleCarousel} ${props.fadeBorder ? styles.fadeBorder : ""}`}>
            <Slider
                infinite={false}
                dots={false}
                slidesToShow={4}
                draggable={false}
                responsive={responsive}
                prevArrow={renderPrevArrow()}
                nextArrow={renderNextArrow()}
            >
                {props.items.map((item, i) => (
                    <Fragment key={i}>{item}</Fragment>
                ))}
            </Slider>
            <style>
                {
                `.slick-disabled .${styles.prev},
                 .slick-disabled .${styles.next} {
                    display: none;
                }`
                }
            </style>
        </div>
    ), [props.items]);
}

export default Carousel;


