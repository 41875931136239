import React from 'react';

import styles from './PageNotFound.module.scss';

const PageNotFound = () => {
  return (
      <div className={styles.container}>
          <div className={styles.content}>
            <h1 className={styles.title}>Page introuvable</h1>
            <h2 className={styles.subtitle}>Le sport n'est pas encore arrivé jusqu'ici</h2>
          </div>
      </div>
  );
}

export default PageNotFound;