import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import Icon from '../../design-system/Icon/Icon';
import { useAuth } from "../../contexts/AuthContext";
import { useAppState } from "../../contexts/StateContext";
import { useRedirect } from "../../hooks/useRedirect";

import styles from "./ArticleCard.module.scss";

const ArticleCard = (props) => {
	const appState = useAppState();
	const { auth } = useAuth();
	const redirect = useRedirect();

	const onClickAction = () => {
		if (auth.canOpenArticle(props.article)) {
			redirect.redirectTo('/globalprev/article/' + props.article.id);
		}
		else if (!auth.isLogged()) {
			appState.modals.login.open();
		}
		else {
			appState.modals.subscribe.open();
		}
	}
	
	return (
		<div className={`${styles.article} ${props.type === 'light' ? styles.light : null}`} style={props.style}>
			<div className={styles.imageLink} onClick={onClickAction}>
				<div className={styles.imageContainer} style={{ backgroundImage: `url(${props.article.image})` }}>
					{props.article.privacy === 'public' && !auth.isLogged() ?
						<div className={styles.freeContentBadge}>
							Contenu gratuit
						</div> : null
					}
					{!auth.canOpenArticle(props.article) && props.article.privacy === 'authenticated' ?
						<div className={styles.locked}>
							<Icon icon="lock" type="light" />
							<div className={styles.lockMessage}>
								Pour débloquer l'article, inscrivez-vous
							</div>
						</div> : null
					}
					{!auth.canOpenArticle(props.article) && props.article.privacy === 'subscribed' ?
						<div className={styles.locked}>
							<Icon icon="credit-card" type="light" />
							<div className={styles.lockMessage}>
								Pour débloquer l'article, abonnez-vous
							</div>
						</div> : null
					}
				</div>
			</div>
			<div className={styles.bottomPart}>
				<p className={styles.articleTitle}>
					{props.article.title}
				</p>
				<p className={styles.articleText}>
					{props.article.description}
				</p>

				<div className={styles.buttonArticle} onClick={onClickAction}>
					Accéder <FontAwesomeIcon icon={faArrowRight} size="sm" />
				</div>
			</div>
		</div>
	);
};

export default ArticleCard;