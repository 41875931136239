import React from 'react';
import { useAuth } from '../../../contexts/AuthContext';

import Button from '../../../design-system/Button/Button';
import Utils from '../../../utils';

import styles from './SubscriptionFormula.module.scss';

const SubscriptionFormula = (props) => {
	const { auth } = useAuth();
	
	const formula = props.formula;

	return (
		<div className={`${styles.subscriptionFormula} ${props.selected ? styles.selected : ''}`}>
			<h1 className={styles.name}>{formula.name}</h1>
			<p className={styles.description}>{formula.description}</p>
			<div className={styles.from}>À partir de</div>
			<div className={styles.price}>{`${Utils.formatPrice(formula.price.amount)} ${formula.price.currency} / ${formula.price.period}`}</div>
			<div className={styles.subscribeArea}>
				{auth.isSubscribedTo(formula.id) ? 
					<span className={styles.subscribedText}>Abonné</span>
				:
					<Button variant='main' onClick={() => props.onButtonClicked(formula.formula)}>S'abonner</Button>
				}
			</div>
		</div>
	)
}

export default SubscriptionFormula;