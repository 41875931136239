import React, { useState } from 'react'

import Input from '../../design-system/Input/Input';
import { api } from '../../config/api';

import styles from './Maintenance.module.scss';
import logo from '../../assets/images/logo.svg';

const Maintenance = (props) => {
	const [adminPassword, setAdminPassword] = useState('')

	const _handleAdminPasswordInputChange = (e) => {
		const value = e.target.value

		setAdminPassword(value)

		api.post('/globalSettings/checkAdminPassword', {
			password: value
		}).then(response => {
			if (response.data.success) {
				props.onAdminLoginSuccess(value)
			}
		}).catch(e => {})
	}

  	return (
		<section className={styles.maintenanceSection}>
			<div className={`${styles.container} ${styles.maintenanceContainer}`}>
				<img src={logo} alt="Logo de Karesport" className={styles.karesportLogo} />
				<h1 className={styles.title}>Maintenance</h1>
				<h2 className={styles.message}>Karesport est actuellement en maintenance afin d'améliorer votre expérience. Merci de rééssayer plus tard.</h2>

				<div className={styles.inputContainer}>
					<Input
						value={adminPassword}
						onChange={_handleAdminPasswordInputChange}
						type="password"
						placeholder="Accès administrateur"
					/>
				</div>
			</div>
		</section>
  	)
}

export default Maintenance