import React from 'react';
import { useAuth } from '../../../../contexts/AuthContext';

import RadioButton from '../../../../design-system/RadioButton/RadioButton';
import Utils from '../../../../utils';

import styles from './SubscriptionFormula.module.scss';

const SubscriptionFormula = (props) => {
	const { auth } = useAuth();

	return (
		<div
			className={`
			 ${styles.subscriptionFormula} 
			 ${props.selected ? styles.selected : ''} 
			 ${auth.isSubscribedTo(props.formula.id) ? styles.bought : ''}`}
			onClick={auth.isSubscribedTo(props.formula.id) ? null : props.onClick}
		>
			<div className={styles.name}>
				{props.formula.name}
			</div>

			<div className={styles.content}>
				<p className={styles.description}>
					{props.formula.description}
				</p>

				<div className={styles.selectFormulaContainer}>
					{auth.isSubscribedTo(props.formula.id) ?
						<span className={styles.subscribedText}>Abonné</span>
					:
					<>
						<div className={styles.priceContainer}>
							<p className={styles.priceLabel}>À partir de </p>
							<p className={styles.price}>
								<b>{Utils.formatPrice(props.formula.price.amount)} { props.formula.price.currency }</b> / { props.formula.price.period }
							</p>
						</div>

						<div className={styles.radioButton}>
							<RadioButton active={props.selected} variant="redGradient" />
						</div>
					</>
					}
				</div>
			</div>
		</div>
	);
};

export default SubscriptionFormula;