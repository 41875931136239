import React, { useState } from 'react';

import { api } from '../../../config/api';

import Input from '../../../design-system/Input/Input';
import Modal from '../../../design-system/Modal/Modal';
import Button from '../../../design-system/Button/Button';
import { useAuth } from '../../../contexts/AuthContext';
import { useAppState } from '../../../contexts/StateContext';
import { useRedirect } from '../../../hooks/useRedirect';

import styles from './LoginModal.module.scss';

const LoginModal = (props) => {
	const appState = useAppState()
	const redirect = useRedirect();
	const { auth } = useAuth();

	const openForgotPassword = () => {
		props.onClose();
		appState.modals.forgotPassword.open();
	}

	const openRegister = () => {
		props.onClose();
		appState.modals.register.open();
	}

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const [error, setError] = useState("");

	const onClose = () => {
		props.onClose();
		setError(null);
	}

	const submit = () => {
		api.post('/auth/login', { email, password })
			.then(response => {
				const data = response.data;
				auth.login(data.token);
				redirect.redirectTo('/account');
				props.onClose();
			})
			.catch(error => {
				if (error.response.data.errors)
					setError(error.response.data.errors.global);
				else
					setError(error.response.data.message);
			});
	}

	return (
		<>
			<Modal
				onClose={onClose}
				show={props.show}
				size="small"
				title="Connectez-vous"
				error={error}>
				<Input
					type="email"
					label="Email"
					placeholder="exemple@exemple.com"
					value={email}
					onChange={e => setEmail(e.target.value)}
				/>
				<Input
					type="password"
					label="Mot de passe"
					placeholder="••••••••••••"
					value={password}
					onChange={e => setPassword(e.target.value)}
				/>

				<div className={styles.loginButtonWrapper}>
					<Button variant="main" onClick={submit}>Se connecter</Button>
				</div>

				<button className={styles.forgotPassword} onClick={openForgotPassword}>J'ai oublié mon mot de passe</button>

				<hr />

				<h1>Pas encore inscrit ?</h1>
				<Button variant="main-outline" onClick={openRegister}>S'inscrire</Button>
			</Modal>
		</>
	);
}

export default LoginModal;